import { useState } from "react";
import { Checkbox } from "../../checkbox/Checkbox";
import { certificateAPI } from "../../../api/apiRequests";
// import { Checkbox } from "antd"; // You can use any UI library or custom checkbox
// import certificateAPI from "../api/certificateAPI"; // Adjust the import to match your setup

const EditCertificateCheckbox = ({
  currentValue=false,
  isEditMode,
  id,
  type
}: {
  currentValue: boolean;
  isEditMode:boolean
  id: number;
  type:string
}) => {
  let [checked, setChecked] = useState(currentValue || false);

  const handleCheckboxChange = async () => {
    const newCheckedValue = (checked=!checked);
    console.log("check value====>",newCheckedValue); 
    setChecked(newCheckedValue);
    if(newCheckedValue!=currentValue){

        await certificateAPI.updateCertificateValues(id,newCheckedValue,type );
    }
  };

  return (
    <input
    className="text-green-600 focus:ring-green-600"
    type={"checkbox"}
    disabled={!isEditMode}
    checked={checked}
    onChange={() => handleCheckboxChange()}
  />
  );
};

export default EditCertificateCheckbox;
