import React, { useState } from "react";
import { roHSAPI } from "../../../api/apiRequests";
import { Input } from "../../input/Input";

const EditRoHSCertificateField = ({
  currentValue,
  type,
  id,
callback,
  index
}: {
  currentValue: string | undefined;
  type: string;
  id: number;
   callback:any;
  index:number
}) => {
  const [value, setValue] = useState(currentValue || "");

  const handleBlur = async () => {
    await roHSAPI.updateRoHSCertificateValues(id, value, type);
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={(e: any) => {
        setValue(e.target.value);
        callback(e.target.value,index);
      }}
      onBlur={handleBlur}
    />
  );
};

export default EditRoHSCertificateField;