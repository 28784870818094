import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { scrappingUrl } from "../../api/constants";
import { Button } from "../button/Button";
import CustomModal from "../custom-modal/CustomModal";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { directMail, fetcher, vdeChecker } from "../../api/SWR/fetchers";
import useProduct from "../../api/SWR/useProduct";
import { certificateAPI, materialAPI } from "../../api/apiRequests";
import _ from "lodash";
import Loader from "../loader/Loader";
import useUser from "../../api/SWR/useUser";
import EditCertificateField from "../ui/edit-certificate-field/EditCertificateField";
import AddCertificate from "../add-certificate/AddCertificate";
import axios from "axios";
import { ROHS_COMPONENT_STATUSES } from "../../constants/enums";

// const tableHeadings = ["source", "reference number", "expire date"];


const MaterialComponentsCertificatesList = ({
  componentName,
  materialCertificatesID,
  requiredCertificates,
  rohsApprove,
  rohsLocalPath,
  collectionDate,
  allCertificates,
    manufactureName,
  type,
  closeModal
  

}: {
  materialCertificatesID: number | boolean;
  componentName: string | undefined;
  closeModal: () => void;
  requiredCertificates: any;
  collectionDate: string | undefined;
  allCertificates: [],
  rohsApprove: boolean;
  rohsLocalPath: string;
    manufactureName: string | undefined;
  type: string | undefined;
}) => {
  const [idCertificateToUpload, setIdCertificateToUpload] = useState<
    boolean | number
  >(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [webLoading, setWebLoading] = useState(false);
  const [tableHeadings, settableHeadings] = useState<Array<string>>( ["source", "reference number", "expire date","C-Doc No."]);

  const [certificatesToDelete, setCertificatesToDelete] = useState<number[]>(
    []
  );
  const [certificatesToDeleteRohs, setCertificatesToDeleteRohs] = useState(
    
  );
  const [isApprove, setIsApprove] = useState(rohsApprove);
  

  const [isMailLoading, setIsMailLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [iframeModalData, setIframeModalData] = useState<
    false | { link: string; body: string }
  >(false);

  const [isAddCertificateModalShown, setIsAddCertificateModalShown] =
    useState(false);

  const user = useUser();
  const isAdmin = user.data.role === 1;
  
  

  

useEffect(()=>{
  if(!isAdmin){
   const tempTable= tableHeadings.filter((value)=>value!="C-Doc No.")
   settableHeadings(tempTable);
  }
},[])


  

  const handleAddCertificateToDelete = (id: number) => {
    if (certificatesToDelete.indexOf(id) < 0) {
      return setCertificatesToDelete((prev: number[]) => [...prev, id]);
    } else
      return setCertificatesToDelete((prev: number[]) =>
        prev.filter((item) => item !== id)
      );
  };

  // const handleDeleteCertificates = async () => {
  //   try {
  //     setIsLoading(true);
  //     await certificateAPI.delete(certificatesToDelete);
  //     await mutate();
  //     toast.success("Deleted!");
  //     setIsLoading(false);
  //   } catch (e) {
  //     toast.error("Something went wrong");
  //     setIsLoading(false);
  //   }
  // };




  const renderTableInput = (
    value: string | undefined,
    type: string,
    id: number
  ) => {
    if (isEditMode) {
      return <EditCertificateField currentValue={value} type={type} id={id} />;
    } else return value;
  };
  const urlChecker = async (parsedData: any, cert: any) => {
    if (cert.serviceName === "VDE") {
      setWebLoading(true);
      const body = {
        model: cert.modelName?cert.modelName:'',
        manufacturer: cert.manufacture?cert.manufacture:'',
        // certificateNumber: cert.referenceNumber ? cert.referenceNumber : (parsedData.certificateNumber ? parsedData.certificateNumber : ''),
        certificateNo: cert?.referenceNumber ?? parsedData?.certificateNumber ?? '',
      };
      vdeChecker(body).then((response: any) => {
        if (response.result) {
          window.open(response.url, "_blank");
          setWebLoading(false);
        } else {
          toast.error("Link not work");
          setWebLoading(false);
        }
      });
    } else {
      console.log(cert.serviceName, "service name in else");
      let certNO = cert?.rawData
        ? parsedData.certificateNumber
        : cert.referenceNumber;
      console.log(certNO, "d");

      const form = document.createElement("form");
      form.method = "post";
      form.action =
        "https://certificatedirectory.intertek.se/Home/GetCertificates";
      // parameters of the form
      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "CertificateNumber";
      input1.value = certNO;
      form.appendChild(input1);
      document.body.appendChild(form);
      form.submit();
    }
  };
  const nameChanger=(name:any)=>{
    // service name
  // 'UlProductiQ',
  // 'CSA',
  // 'SgsTuevSaar',
  // 'Astabeab',
  // 'Certipedia',
  // 'ETLDirectory',
  // 'ETLEUDirectory',
  // 'SDirectory',
  // 'VDE',
  if (name=='Certipedia') {
    return 'TÜV'
  }
  else if (name=='TUV Rheinland') {
    return 'TÜV'
  }
  
  else if(name=='SDirectory'){
    return 'Intertek'
  }
  else if(name=='UlProductiQ') {
    return 'UL'
  }else{
    return name
  }
}
  const renderSource = (
    source: string,
    iframeSrc: { link: string; body: string },
    id: number,
    serviceName: string,
    parsedData: any,
    cert: any
  ) => {
    return isEditMode ? (
      renderTableInput(source, "source", id)
    ) : source ? (
      // serviceName === "VDE" || 
      serviceName === "SDirectory" ? (
        <p
          onClick={() => urlChecker(parsedData, cert)}
          className="underline text-primary_color hover: cursor-pointer"
        >
          {serviceName ? nameChanger(serviceName) : "Source link"}
        </p>
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          className="text-primary_color underline"
          href={source}
          target="_blank"
          rel="noopener"
        >
          {serviceName ? nameChanger(serviceName) : "Source link"}
        </a>
      )
    ) : (
      ""
    );
    // }
  };
  return (
    <>
      {webLoading ? (
        <Loader />
      ) : (
        <div className={styles.certificatesModalWrap}>
          <h2 className="text-1xl leading-10 font-medium text-primary-500">
              {manufactureName} 
            </h2>
          <div className={"flex justify-between"}>
            <h2 className="text-1xl leading-10 font-medium text-primary-500">
              {componentName}
            </h2>
           
          </div>
          <div className={"flex justify-between"}>
            <h2 className="text-2xl leading-10 font-medium mb-4 text-primary-500">
              Certification Records
            </h2>
          </div>
          <div className={styles.tableWrapper}>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {tableHeadings.map((heading, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {allCertificates.map((cert: any, index: number) => {
                  let parsedData = JSON.parse(cert?.rawData);
                  // console.log(cert, "cert");

                  // console.log(parsedData, 'parsed');

                  return (
                    <tr
                      className={index % 2 === 0 ? undefined : "bg-gray-50"}
                      key={cert.id}
                    >
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {renderSource(
                            cert?.source,
                            cert?.dataForIframe,
                            cert.id,
                            cert.serviceName,
                            parsedData,
                            cert
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {renderTableInput(
                            cert?.rawData
                              ? parsedData.certificateNumber
                              : cert.referenceNumber,
                            "referenceNumber",
                            cert.id
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {renderTableInput(
                            cert?.expireDate,
                            "expireDate",
                            cert.id
                          )}
                        </div>
                   
                      </td>
                     {
                      isAdmin &&<td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div style={{ maxWidth: 150 }}>
                        {<div style={{ maxWidth: 150 }}>{cert?.doc ? cert.doc.certainliDocNo : ''}</div>}
                      </div>
                    </td>
                     }
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
{/* 
          <div className={"flex justify-between"}>
            <h2 className="text-2xl leading-10 font-medium mb-4 text-primary-500">
              RoHS / REACH Document
            </h2>

            </div> */}
          {/* <div className={styles.tableWrapper}>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {rohsTableHeadings.map((heading, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
             { !rohsLocalPath ? <p></p> :  <tr className={"bg-gray-50"}>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>
                      {renderSourceClick(rohsLocalPath, manufactureName)}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>
                      {renderTableInput(
                        collectionDate,
                        "rohsCollectionDate",
                        +materialCertificatesID
                      )}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <div style={{ maxWidth: 150 }}>{type}</div>
                  </td>
                 
                  <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                    <input
                      className="text-green-600 focus:ring-green-600"
                      type={"checkbox"}
                      checked={isApprove}
                      onChange={async () =>                        
                        approveStatus()
                      }
                    />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                    <input
                      className="text-green-600 focus:ring-green-600"
                      type={"checkbox"}
                      // checked={materialCertificatesID}
                      onChange={() =>
                        handleAddCertificateToDeleteRohs(+materialCertificatesID)
                      }
                    />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div> */}

          {/* <div className="flex items-center h-10 bg-gray-50 text-sm text-gray-500 px-5">
            <p className="flex flex-1">REQUIRED CERTIFICATES</p>
            <p className="flex flex-1">
              {_.join(
                requiredCertificates?.map(
                  (item: { title: string; userId: number }) => item.title
                ),
                ", "
              )}
            </p>
          </div> */}
        </div>
      )}
      <CustomModal
        setModalShown={() => setIdCertificateToUpload(false)}
        isShown={!!idCertificateToUpload}
      >
        <div className={styles.addModalWrap}>
          <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500">
            Upload new certificate
          </h2>
          <div className={"mt-6"}>
            <FileUploader
              handleChange={setFile}
              onSizeError={(err: string) => toast.error(err)}
              onTypeError={(err: string) => toast.error(err)}
              name="file"
              classes={"DragDropFiles"}
              types={["pdf"]}
              maxSize={10}
              children={
                <div className="DragDropFiles__body">
                  <Upload className="DragDropFiles__icon" />
                  <div className="DragDropFiles__body__title">
                    Upload a file <span>or drag and drop</span>
                  </div>
                  <div className="DragDropFiles__body__subtitle">
                    PDF up to 10MB
                  </div>
                </div>
              }
            />
            {}

            {file ? (
              <div className="AddProduct__fileView">
                <div className="AddProduct__fileView__left">
                  <Document className="AddProduct__fileView__icon" />
                  <div className="AddProduct__fileView__text">{file?.name}</div>
                </div>
                <div
                  className="AddProduct__fileView__right"
                  onClick={() => setFile(null)}
                >
                  Remove
                </div>
              </div>
            ) : null}
          </div>

        </div>
      </CustomModal>
   
      {iframeModalData && (
        <CustomModal
          setModalShown={() => setIframeModalData(false)}
          isShown={!!iframeModalData}
        >
          <iframe
            className={styles.iframeModal}
            src={`${iframeModalData?.link}/?${iframeModalData.body}`}
          />
        </CustomModal>
      )}
    </>
  );
};

export default MaterialComponentsCertificatesList;
