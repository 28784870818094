import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { productApi } from "../../api/apiRequests";
import { toast } from "react-toastify";
import { Input } from "../input/Input";
import SelectList from "../selectList/SelectList";
import { ProductType } from "../../types";
import UseFromFieldsData from "../../api/SWR/useFromFieldsData";
import Loader from "../loader/Loader";
import { Textarea } from "../textarea/Textarea";
import { Button } from "../button/Button";
import styles from "./styles.module.scss";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { selectCustomStyles } from "../../constants/selectStyles";
import CloseIcon from "../../static/assets/png/close.png";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  // type: Yup.array().required("Type is required"),
  // standards: Yup.array().required("Standard is required"),
  // markets: Yup.array().required("Markets is required"),
  // description: Yup.string(),
  // developmentStatus: Yup.object().required("Status is required"),
});

const EditProductModal = ({
  product,
  setProductToEdit,
  mutate,
}: {
  product: ProductType;
  setProductToEdit: any;
  mutate: any;
}) => {
  const { data: formData, isLoading } = UseFromFieldsData();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      id: product.id,
      name: product.name,
      description: product.description,
      type: product.type,
      standards: product.standards,
      markets: product.markets,
      developmentStatus: product.developmentStatus,
      manufacturer: product.manufacturer,
      customer: product.customer,
    },
    onSubmit: async (values, { setErrors }) => {
      console.log("Form submitted"); // Log for debugging
      try {
        setLoading(true);
        await productApi.editProduct(
          values.id,
          values.name,
          values.description,
          values.type,
          values.standards,
          values.markets,
          values.developmentStatus,
          values.manufacturer,
          values.customer
        );

        
        await mutate();
        setLoading(false);
        setProductToEdit(null);
         formik.handleReset("");
      } catch (e) {
        console.log("Error:", e); // Log for debugging
        toast.error(e?.response?.data);
        setLoading(false);
          // @ts-ignore
          setErrors({ error: e?.response?.data });
      }
    },
  });

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        setProductToEdit(null);
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles.contentWrapper}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={formik.handleSubmit} className={styles.formWrapperCss}>
            <img
              onClick={() => setProductToEdit(null)}
              src={CloseIcon}
              className={styles.closeIcon}
              width={15}
              height={15}
              alt="closeIcon"
            />
            <h2 className="text-2xl leading-8 font-semibold pb-6">
              Edit Product
            </h2>
            <div className="flex justify-between mb-6">
              <div className={"w-5/12"}>
                <Input
                  type="text"
                  label="Product Name/Number*"
                  id="name"
                  name="name"
                  onChange={formik?.handleChange}
                  value={formik?.values?.name}
                  errorText={formik?.errors?.name}
                  placeholder="Enter a product Name/Number"
                />
              </div>
              <div className={"w-5/12"}>
                <label>Product Standards</label>
                <CreatableSelect
                  styles={selectCustomStyles}
                  placeholder={"Select Product Standards"}
                  isMulti
                  value={formik.values.standards.map((value: any) => ({
                    value: value.id,
                    label: value.title,
                  }))}
                  options={formData.standards.map(
                    (option: { id: number; title: string }) => ({
                      value: option.id,
                      label: option.title,
                    })
                  )}
                  onChange={(item: any) => {
                    let newArr;
                    if (item) {
                      newArr = item.map(
                        (standart: { label: number; value: string }) => ({
                          id: standart.value,
                          title: standart.label,
                        })
                      );
                      formik.setFieldValue("standards", newArr);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className={"w-5/12"}>
                <div>
                  <label>Product Type</label>
                  <CreatableSelect
                    styles={selectCustomStyles}
                    placeholder={"Select Product Type"}
                    options={formData.productType.map(
                      (option: { id: number; title: string }) => ({
                        value: option.id,
                        label: option.title,
                      })
                    )}
                    onChange={(item: any) => {
                      formik.setFieldValue("type", [
                        {
                          id: item.value,
                          title: item.label,
                        },
                      ]);
                    }}
                    value={
                      formik.values?.type[0]?.id
                        ? ({
                            value: formik.values.type[0].id,
                            label: formik.values.type[0].title,
                          } as any)
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className={"w-5/12"}>
                <label>Markets</label>
                <Select
                  styles={selectCustomStyles}
                  placeholder={"Select one or more"}
                  isMulti
                  options={formData.markets.map(
                    (option: { id: number; title: string }) => ({
                      value: option.id,
                      label: option.title,
                    })
                  )}
                  value={formik.values.markets.map((value: any) => ({
                    value: value.id,
                    label: value.title,
                  }))}
                  onChange={(item: any) => {
                    let newArr;
                    if (item) {
                      newArr = item.map(
                        (market: { label: number; value: string }) => ({
                          id: market.value,
                          title: market.label,
                        })
                      );
                      formik.setFieldValue("markets", newArr);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className={"w-5/12"}>
                <Input
                  type="text"
                  label="Product Manufacturer"
                  id="manufacturer"
                  name="manufacturer"
                  onChange={formik?.handleChange}
                  value={formik?.values?.manufacturer}
                  errorText={formik?.errors?.manufacturer}
                  placeholder="Enter a product manufacturer"
                />
              </div>
              <div className={"w-5/12"}>
                <SelectList
                  label="Development Status"
                  placeholder={"Select one or more"}
                  options={formData.developmentStatus.map(
                    (option: { id: number; title: string }) => ({
                      value: option.id,
                      label: option.title,
                    })
                  )}
                  value={
                    formik.values.developmentStatus
                      ? ({
                          value: formik.values.developmentStatus.id,
                          label: formik.values.developmentStatus.title,
                        } as any)
                      : null
                  }
                  onChange={(item: any) => {
                    if (item) {
                      formik.setFieldValue("developmentStatus", {
                        id: item.value,
                        title: item.label,
                      });
                    }
                  }}
                />
              </div>
            </div>
              {/* <div className={"mb-6"}>
              <div className={"w-5/12"}>
                <Input
                  type="text"
                  label="customer"
                  id="customer"
                  name="customer"
                  onChange={formik?.handleChange}
                  value={formik?.values?.customer}
                  errorText={formik?.errors?.customer}
                  placeholder="Enter a product customer"
                />
              </div>
            </div> */}
            <div className={"mb-6"}>
              <Textarea
                label="Description"
                id="description"
                name="description"
                placeholder="Enter a product description..."
                value={formik?.values?.description}
                onChange={formik?.handleChange}
              />
            </div>
            <div>
              <Button loading={loading} label={"Save changes"} type={"submit"} />
              
                {/* <Button label={"Save changes"} disabled={loading} type="submit" /> */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditProductModal;
