import axios from "axios";
import useUser from "../../api/SWR/useUser";
import { baseUrl } from "../../api/constants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "../button/Button";
import { DOCUMENTCERTIFICATES } from "../../constants/enums";
import EditRoHSCertificateField from "../ui/edit-certificate-field/EditRoHSCertificateField";
import { Input } from "../input/Input";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import styles from "../materials-table/styles.module.scss";

const DocumentsCertificates = () => {

  let [cert, setCert] = useState([])
  const [loading, setLoading] = useState<boolean>(false)





  const [searchDD, setSearchDDValue] = useState<any>(DOCUMENTCERTIFICATES.source);
  const [searchValue, setSearchValue] = useState<string>('')
  const [callbackState, setCallbackState] = useState<object>({});
  let [tempCertData, settempCerts] = useState([])
  let [selectedManufacturer, setselectedManufacturer] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCountValue, setPageCountValue] = useState<any>(0);
  let [showingData, setShowingData] = useState([]);
  const itemsPerPage = 10;
  let pageCount = Math.ceil(pageCountValue / itemsPerPage);
  const tableHeadings = [
    "source",
    "reference number",
    "Manufacturer",
    "Type",
    "Coverage",
  ];

  const getCertificates = async (page: number=4, limit: number=40) => {
    setLoading(true);
    try {
      const response = await axios.get(baseUrl + "/certificate/get-cert",

        {
          params: {
            "page": page,
            "limit": limit
        }
        }
      );
      if (response.status == 200) {
        const data:[] = response.data;
         
        setCert([...cert,...data]);
        let length = data.length + cert.length;
        setPageCountValue(length);


      }
    } catch (e) {
      toast.error(e?.response?.data);
    }
    setLoading(false);

  }


  useEffect(() => {
    getCertificates();
  }, [])

  useEffect(() => {

    let updatedData = cert.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    console.log("updated Data ==>>>", updatedData);

    setShowingData(updatedData);
    console.log(updatedData, "showingData calling");

}, [currentPage, cert]);

useEffect(() => {
    getCertificates();
}, [])

  function ManufactureCallback(value: any, index: number) {
    if (value) {
      selectedManufacturer[index] = value;
      setselectedManufacturer(selectedManufacturer);
    }
  }
  const renderRoHSTableInput = (
    value: string | undefined,
    type: string,
    id: number,
    index: number,
  ) => {
    if (isEditMode) {
      return <EditRoHSCertificateField currentValue={value} type={type} id={id} callback={ManufactureCallback} index={index} />;
    } else return value;
  };
  const showPagesCount = (pageCount: number, currentPage: number) => {
    let array: number[] = Array.from(
        new Set([
            1,
            2,
            3,
            currentPage < 2 ? 1 : currentPage - 1,
            currentPage,
            currentPage >= pageCount ? pageCount : currentPage + 1,
            pageCount - 2,
            pageCount - 1,
            pageCount,
        ])
    )
        .sort((a, b) => a - b)
        .filter((el) => el > 0 && el <= pageCount);
    for (let i = 1; i < array.length; i++) {
        if (array[i] - 1 !== array[i - 1]) {
            array = [...array.slice(0, i), 0, ...array.slice(i)];
            i++;
        }
    }

    return array;
};
  const SelectComponent = ({ value, onChange }: any) => {
    return (
      <td className="whitespace-nowrap align-top text-sm text-gray-500">{
        <select
          className="component-select rounded py-1.5 border border-gray-200 w-24 mr-1.5"
          // className={styles.componentSelect}
          onChange={onChange}
          value={value}
          name="componentStatus"
          id="component-status"
        >

          <option value={DOCUMENTCERTIFICATES.type}>TYPE</option>
          <option value={DOCUMENTCERTIFICATES.source}>SOURCE NAME</option>
          <option value={DOCUMENTCERTIFICATES.referenceNumber}>REFERENCE NUMBER</option>
          <option value={DOCUMENTCERTIFICATES.manufacturer}>MANUFACTURER</option>

        </select>
      }
      </td>
    );
  };
  const handleSelectChange = (e: any) => {
    console.log("======> ", e.target.value)
    setSearchDDValue(e.target.value);


  };
  const searchHandler = (e: any) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      console.log("-----====////////>", e.target.value)

      setCallbackState({})
    }

  }
  const submittSearch = () => {
    if (searchValue.length == 0) {
      // alert("Write something to search")
      toast.error("Write Something to Search");
      return;
    }
    const regex = /[.,\/#%*+\s-]/g;
    const query = searchValue.replace(regex, "");
    const tempMap = {
      "type": searchDD,
      "query": query.trim().toLowerCase()
    };
    console.log("serach value ==>", showingData);
    if (tempCertData.length > 0) {
      showingData = tempCertData;
    } else {
      settempCerts(showingData);
    }
    // setCallbackState(tempMap);
    const filteredItems = showingData.filter((item: any) => {

      let manufacturer
      if (tempMap['type'] == DOCUMENTCERTIFICATES.type) {

        // if(item.rawVersion==""|| item.rawVersion==null){
        if (item.material) {
          item.rawVersion = item.material?.type ?? item.rawVersion;

        }
        // }

      }
      if (tempMap['type'] == DOCUMENTCERTIFICATES.referenceNumber) {
        if (item.rawData.includes("cert")) {

            
        let  parsedData = JSON.parse(item.rawData);
        // item.referenceNumber= (parsedData?.certificateNumber != "" ? parsedData.item.certificateNumber : parsedData?.certificateNumber!=""? parsedData?.certificateNumber:item.referenceNumber);
       const tempDAta= parsedData?.certificateNumber != "" ?parsedData?.certificateNumber: parsedData?.item?.certificateNumber??"" ;
       if(tempDAta){
        item.referenceNumber=tempDAta;
       }else{
        item.referenceNumber=item.referenceNumber;
       }
                                  

     
                                  

        }
      }
      if (tempMap['type'] == DOCUMENTCERTIFICATES.manufacturer) {

        if (item.rawData.includes("cert")) {

            
        let  parsedData = JSON.parse(item.rawData);
        item.manufacture= (parsedData?.manufacturedPlant!=""?parsedData?.manufacturedPlant:item.manufacture );

                                  

        }
      }


      manufacturer = item[tempMap['type']]; // Convert to lowercase for case-insensitive comparison

      if (manufacturer) {
        let queryLower: string =
          tempMap["query"];

        manufacturer = manufacturer.toLowerCase();

        const regex = /[.,\/#%*+\s-]/g;
        const filterString = manufacturer.replace(regex, "");


        console.log("check ==----->", filterString);

        return filterString.includes(queryLower);
      }
      return;

    });
    console.log("callback ===>", filteredItems);
    if (filteredItems.length > 0) {
      toast.success("Success!");
    } else {
      toast.error("No Component Found");
    }
    setShowingData(filteredItems);

  }
  return (

    <>
      <div className="flex">
        {SelectComponent({ value: searchDD, onChange: handleSelectChange })}
        <Input
          style={{ width: "250px" }}
          value={searchValue}

          onChange={searchHandler}
          placeholder="Search"
          inputValue={searchValue}

          handleClearInput={() => {
            setCallbackState({});
            setSearchValue("");
            if (tempCertData.length > 0) {
              setShowingData(tempCertData)
              settempCerts([]);

            }
          }
          }
        ></Input>
        <Button
          label=
          "Search"

          className={"mr-2.5, ml-1.5"}
          onClick={submittSearch}
        />
      </div>


      {!loading &&
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeadings.map((heading) => (
                        <th
                          key={heading}
                          scope="col"
                          className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                        >
                          {heading}
                        </th>
                      ))}
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">

                    {showingData.map((item: any, index: number) => {
                      let check = false;
                      let parsedData
                      // = {
                      //   certificateNumber: "",
                      //   item: {
                      //     certificateNumber: "",

                      //   }
                      // }
                      if (item.rawData.includes("cert")) {
                         check = true;

                        parsedData = JSON.parse(item?.rawData);
                        console.log("parse data ",parsedData);

                      }
                      // if(!check){
                      //   return;
                      // }
                      // console.log(item, "cert");

                      console.log(parsedData, 'parsed');


                      return (
                        <tr
                          className={index % 2 === 0 ? undefined : "bg-gray-50"}
                          key={item.id}
                        >
                          <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div style={{ maxWidth: 150 }}>
                              {item.serviceName}
                              {/* <p>cert?.source && cert?.source.includes("http") ? cert?.source : cert?.localPath</p> */}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div style={{ maxWidth: 150,overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              <p>

                                {check && item?.rawData
                                  ?
                                  parsedData?.certificateNumber != "" ?parsedData?.certificateNumber: parsedData?.item?.certificateNumber??"" 
                                  : item.referenceNumber
                                }
                              </p>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {parsedData?.manufacturedPlant!=""?parsedData?.manufacturedPlant:item.manufacture}
                            </div>
                          </td>



                          <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div style={{ maxWidth: 150,overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {item.material?.type ? item.material.type : item.rawVersion ? item.rawVersion : ""}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div style={{ maxWidth: 150 }}>
                              <a className="text-primary_color hover:underline" href={`${item?.source ?? item.sourceCert}`}>Show Detail</a>
                            </div>
                          </td>


                        </tr>
                      );

                    })}

                  </tbody>
                </table>
              </div>

              <nav
                            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                        >
                            <button
                                onClick={() =>
                                    setCurrentPage((currentPage) => currentPage - 1)
                                }
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>

                            {showPagesCount(pageCount, currentPage).map(
                                (elem, ind) => {
                                    if (elem === 0) {
                                        return (
                                            <div
                                                key={elem}
                                                aria-current="page"
                                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                                            >
                                                ...
                                            </div>
                                        );
                                    }
                                    return (
                                        <button
                                            key={elem}
                                            onClick={() => setCurrentPage(elem)}
                                            aria-current="page"
                                            className={
                                                elem === currentPage
                                                    ? styles.activeBtn
                                                    : styles.defaultBtn
                                            }
                                        >
                                            {elem}
                                        </button>
                                    );
                                }
                            )}

                            <button
                                // disabled={pageCount === currentPage}
                                onClick={() =>
                                    setCurrentPage((currentPage) => {
                                        if(pageCount === currentPage){
                                            getCertificates(currentPage+3,30)
                                        }
                                        console.log("page count   ", currentPage + 1);
                                        return currentPage + 1
                                    })
                                }
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </nav>
            </div>
          </div>
        </div>}

    </>
  );
};






export default DocumentsCertificates;