import React, { useState } from "react";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetcher } from "../../api/SWR/fetchers";
import { toast } from "react-toastify";
import { Input } from "../input/Input";
import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { Button } from "../button/Button";

const validationSchema = Yup.object().shape({
  serviceName: Yup.string().required("Required"),
  // rawVersion: Yup.string().required("Required"),
  modelName: Yup.string().required("Required"),
  materialId: Yup.number().required("Required"),
  source: Yup.string().required("Required"),
  // referenceNumber: Yup.string().required("Required"),
  // expireDate: Yup.string().required("Required"),
});

const AddCertificate = ({
  materialId,
  mutate,
  closeModal,
}: {
  materialId: number | boolean;
  mutate: any;
  closeModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const formik = useFormik({
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues: {
      serviceName: "",
      modelName: "",
      materialId: materialId,
      source: "",
      referenceNumber: "",
      expireDate: "",
    },
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      if (materialId) {
        try {
          const rebuildData = (values: any) => {
            let formData = new FormData();
            Object.keys(values).forEach((key) => {
              formData.append(key, values[key]);
            });
            return formData;
          };
          let data = rebuildData(values);
          if (file !== null) {
            data.append("file", file);
          }
          await fetcher({
            key: "certificate/save",
            data: data,
          });
          await mutate();
          closeModal();
          toast.success("Material created!");
          //closeModal();
        } catch (e) {
          setIsLoading(false);
          toast.error("Something went wrong");
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <div className={styles.wrapper}>
      <h2 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500 mb-5">
        Add new certificate
      </h2>
      <div  className="overflow-auto h-72">
      <form onSubmit={formik.handleSubmit}>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Service Name"
            id="serviceName"
            name="serviceName"
            onChange={formik?.handleChange}
            value={formik?.values?.serviceName}
            errorText={formik?.errors?.serviceName}
            placeholder="Enter a certificate serviceName"
          />
        </div>
        {/* <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Raw Version"
            id="rawVersion"
            name="rawVersion"
            onChange={formik?.handleChange}
            value={formik?.values?.rawVersion}
            errorText={formik?.errors?.rawVersion}
            placeholder="Enter a certificate raw version"
          />
        </div> */}
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Model Name"
            id="modelName"
            name="modelName"
            onChange={formik?.handleChange}
            value={formik?.values?.modelName}
            errorText={formik?.errors?.modelName}
            placeholder="Enter a certificate modelName"
          />
        </div>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Source"
            id="source"
            name="source"
            onChange={formik?.handleChange}
            value={formik?.values?.source}
            errorText={formik?.errors?.source}
            placeholder="Enter a certificate source"
          />
        </div>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Reference Number"
            id="referenceNumber"
            name="referenceNumber"
            onChange={formik?.handleChange}
            value={formik?.values?.referenceNumber}
            // errorText={formik?.errors?.source}
            placeholder="Enter a certificate reference number"
          />
        </div>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="Expire Date"
            id="expireDate"
            name="expireDate"
            onChange={formik?.handleChange}
            value={formik?.values?.expireDate}
            // errorText={formik?.errors?.expireDate}
            placeholder="Enter a certificate expire date"
          />
        </div>

        <div className={"mb-5"}>
          <FileUploader
            error={"dsd"}
            handleChange={setFile}
            onSizeError={(err: string) => toast.error(err)}
            onTypeError={(err: string) => toast.error(err)}
            name="file"
            classes={"DragDropFiles"}
            types={["pdf"]}
            maxSize={10}
            children={
              <div className="DragDropFiles__body">
                <Upload className="DragDropFiles__icon" />
                <div className="DragDropFiles__body__title">
                  Upload a file <span>or drag and drop</span>
                </div>
                <div className="DragDropFiles__body__subtitle">
                  PDF up to 10MB
                </div>
              </div>
            }
          />

          {file ? (
            <div className="AddProduct__fileView">
              <div className="AddProduct__fileView__left">
                <Document className="AddProduct__fileView__icon" />
                <div className="AddProduct__fileView__text">{file?.name}</div>
              </div>
              <div
                className="AddProduct__fileView__right"
                onClick={() => setFile(null)}
              >
                Remove
              </div>
            </div>
          ) : null}
        </div>
        <div className={"flex justify-end"}>
        <Button loading={isLoading} label={"Add"} type={"submit"} />
        </div>
      </form>
      </div>
    </div>
  );
};

export default AddCertificate;
