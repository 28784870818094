import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import CustomModal from "../custom-modal/CustomModal";
import useUser from "../../api/SWR/useUser";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { materialAPI, productApi } from "../../api/apiRequests";
import MaterialComments from "../material-comments/MaterialComments";
import { Button, BUTTON_COLORS } from "../button/Button";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/solid";
import MatchScoreColumn from "./match-score-column/MatchScoreColumn";
import MaterialCertificatesList from "../material-certificates-list/MaterialCertificatesList";
import Tooltip from "react-tooltip-lite";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import AddCertificate from "../add-certificate/AddCertificate";
import EditMaterial from "../ui/edit-material/EditMaterial";
import { toast } from "react-toastify";
import _ from "lodash";
import closeIcon from "../../static/assets/png/close.png";
import receiverPhone from "../../static/assets/svg/receiver_phone.svg";
import message from "../../static/assets/svg/message.svg";
import link from "../../static/assets/svg/link.svg";
import location from "../../static/assets/svg/location.svg";
import {
  emailToManufacturer,
  fetcher,
  flagMailNotification,
  getManufactureMails,
  getMaterialStatus,
  manufacturerMails,
  scrappingFetcher,
  updateMaterialStatus,
} from "../../api/SWR/fetchers";
import { Textarea } from "../textarea/Textarea";
import { Input } from "../input/Input";
import { defaultEmailText, receiverEmails } from "../../api/constants";
import Loader from "../loader/Loader";
import {
  CERTIFICATE_COLLECTION_STATUSES,
  COMPONENT_STATUSES,
  ROHS_COMPONENT_STATUSES,
} from "../../constants/enums";
import RoHSCertificatesList from "../material-certificates-list/RoHSCertificatesList";
import { FileUploader } from "react-drag-drop-files";
import { roHSAPI } from "../../api/apiRequests";

const CriticalCheckbox = ({
  id,
  value,
  handleChangeCritical,
  disable,
}: {
  id: number;
  value: boolean;
  handleChangeCritical: any;
  disable?: boolean;
}) => {
  const [isChecked, setChecked] = useState(value);

  return (
    <>
      <div className="">
        <div className="w-4 flex m-auto group  relative hover:cursor-pointer">
          <input
            type="checkbox"
            name="critical"
            disabled={disable}
            checked={isChecked}
            onChange={() => {
              handleChangeCritical(id, !value);
              setChecked(!isChecked);
            }}
            className="text-green-600 focus:ring-green-600 hover:cursor-pointer"
          />
          <span
            className={` ${disable ? "visible" : "invisible"
              } group-hover:opacity-100 transition-opacity bg-gray-900  text-sm text-gray-100 rounded-md absolute -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto -top-20 -left-32 p-2 pointer-events-none`}
          >
            <p className=" text-gray-200">
              Critical status can only be changed <br />
              when the product is reverted to "In Review"
            </p>
          </span>
        </div>
      </div>
    </>
  );
};
interface loadingInterface {
  materialId?: number;
  loading: boolean;
}
export default function MaterialsTable({
  materials,
  isDetect,
  wsCertificates,
  setIsDetect,
  mutate,
  materialsToDelete,
  setMaterialsToDelete,
  productId,
  productStatus,
  isEditMode,
  mainPageLoader,
  callbackSearchValue,
}: {
  materials: any[];
  wsCertificates: any;
  mutate: any;
  isDetect: any;
  setIsDetect: any;
  materialsToDelete: number[];
  setMaterialsToDelete: any;
  productId: number;
  productStatus: any;
  isEditMode: boolean;
  mainPageLoader: (event: boolean) => void;
  callbackSearchValue: any;
}) {
  const { data } = useUser();
  const isAdmin = (data.role === 1);
  const isRoHSAllowed = data.isRoHSAllow;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMaterial, setSelectedMaterial] = useState<any>();
  const [showingData, setShowingData] = useState<any>();
  const [rohsFiles, setRoHSFiles] = useState([]);
  const [digiKeyFile, setDigiKeyFile] = useState<File | null>(null);
  const [filteredMaterials, setFilteredMaterials] = useState<any>();
  const [searchMaterials, setSearchMaterials] = useState<any>([]);
  const [tempPage, setTempPage] = useState<any>();
  const [pageCountValue, setPageCountValue] = useState<any>(0);
  // let pageCount = Math.ceil(filteredMaterials?.length / itemsPerPage);
  let pageCount = Math.ceil(pageCountValue / itemsPerPage);
  const [materialCertificatesID, setMaterialCertificatesID] = useState<
    number | boolean
  >(false);
  const [idDigiKeyToUpload, setIdDigiKeyToUpload] = useState<
    boolean | number
  >(false);
  const [idDigiKeyToUpdate, setIdDigiKeyToUpdate] = useState<
    boolean | number
  >(false);
  const [digiKeyURL, setDigiKeyURL] = useState<
    string
  >();
  const [rohsCertificatesID, setRoHSCertificatesID] = useState<
    number | boolean
  >(false);

  const [rohsCollectionDate, setRoHSCollectionDate] = useState<string>();
  const [flagMailLoading, setFlagMailLoading] = useState<boolean>(false);
  const [digiKeyLoading, setDigiKeyLoading] = useState<boolean>(false);

  const [rohsCertificateApprove, setRoHSCertificateApprove] =
    useState<boolean>(false);

  const [isRohsEmailPopUp, setIsRohsEmailPopUp] = useState<boolean>(false);

  const [roHSCertificatesPath, setRoHSCertificatesPath] = useState<string>("");

  const [manufactureName, setManufactureName] = useState<string>();
  const [materialComment, setMaterialComment] = useState<string>("");

  const [certificateType, setCertificateType] = useState<string>();

  const [emailDetails, setEmailDetails]: any = useState({});
  const [materialCertificatesName, setMaterialCertificatesName] =
    useState<string>();
  const [requiredCertificates, setRequiredCertificates] = useState();
  const [isCommentsModalShown, setCommentsModalShown] = useState<null | number>(
    null
  );

  let emailTextObj = {
    materialId: 0,
    manufacturer: "",
    isRoHSModel: false,
    model: "",
    adminPopup: false,
    customerPopup: false,
    customerText: defaultEmailText,
    selectedText: "Contacting Manufacturer",
    loader: false,
    confirmEmailPopup: false,
    address: undefined,
  };

  defaultEmailText.replace("firstName", data?.firstName);
  defaultEmailText.replace("lastName", data?.lastName);
  defaultEmailText.replace("myCompany", data?.company?.name ?? "Company");
  defaultEmailText.replace("myComponent", emailTextObj.model);
  defaultEmailText.replace("myManufacture", emailTextObj.manufacturer);

  emailTextObj.customerText = defaultEmailText;

  const [emailTextModal, setEmailTextModal] = useState(emailTextObj);
  const [emails, setEmails] = useState({
    receiverEmail: receiverEmails.receiver,
    ccEmails: receiverEmails.ccEmails,
    emailText: "",
    errorText: "",
  });

  const [recipientEditMode, setRecipientEditMode] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  // filters States
  const [criticalFilter, setCriticalFilter] = useState<any>(false);
  const [certificateFilter, setCertificateFilter] = useState<any>(false);
  const [tempCertsFilter, setTempCertsFilter] = useState<any>(false);
  const [internalComponentsFilter, setInternalComponentsFilter] = useState<any>(false);
  const [manufactureFilter, setManufactureFilter] = useState<any>(false);
  const [modelNameFilter, setModelNameFilter] = useState<any>(false);
  const [typeFilter, setTypeFilter] = useState<any>(false);


  const [materialId, setMaterialId] = useState<number | boolean>(false);
  const [rohsLoader, setRohsLoader] = useState<boolean>(false);
  const [manufactureMails, setManufactureMails] = useState<boolean>(false);
  const [allMaterialsManufacturerName, setAllMaterialsManufacturerName] =
    useState([]);
  const [isScrappingLoading, setIsScrappingLoading] = useState<
    Array<loadingInterface>
  >([]);
  const [materialStatus, setMaterialStatus] = useState([]);


  useEffect(() => {

    if (callbackSearchValue['query']) {
      const filteredItems = filteredMaterials.filter((item: any) => {

        let manufacturer = item[callbackSearchValue['type']]; // Convert to lowercase for case-insensitive comparison

        if (manufacturer) {
          manufacturer = manufacturer.toLowerCase();
          const regex = /[.,\/#%*+\s-]/g;
          const filterString = manufacturer.replace(regex, "");
          let queryLower: string =
            callbackSearchValue["query"];
          console.log("check ==----->", filterString);

          return filterString.includes(queryLower);
        }
        return;

      });

      console.log("callback ===>", filteredItems);
      if (filteredItems.length > 0) {
        toast.success("Success!");
      } else {
        toast.error("No Component Found");
      }
      // setTempPage(currentPage);
      setCurrentPage(1);
      setSearchMaterials(filteredItems)
      setShowingData(filteredItems);
      setPageCountValue(filteredItems.length);



    } else {
      setSearchMaterials([])

      if (filteredMaterials) {
        setValue()
        //   setShowingData([])

        //   setShowingData(filteredMaterials);
        //   // setPageCountValue(filteredMaterials.length);
      }


    }



  }, [callbackSearchValue])

  useEffect(() => {

    let tempLoading: any = [];
    materials.map((item) => {
      let obj = { loading: false, materialId: item.id };
      tempLoading.push(obj);
    });
    setIsScrappingLoading(tempLoading);
  }, [materials]);

  useEffect(() => {
    if (wsCertificates.length >= 1) {
      stopLoading();
    }
    if (isDetect) {
      certsFinishChecker();
      setIsDetect(false);
    }
  }, [wsCertificates]);

  useEffect(() => {
    fetchMaterialStatus();
  }, []);
  function fetchMaterialStatus() {
    getMaterialStatus().then((response: any) => {
      setMaterialStatus(response.data);
    });
  }

  const getBaseValue = (items: any, material: any) => {
    let data = JSON.parse(items);
    let full = data.some(
      (item: any) =>
        `${item.manufacture} ${item.model}`.trim() ===
        `${material.manufacturer} ${material.componentName}`
    );
    let partial = data.some(
      (item: any) =>
        `${item.manufacture} ${item.model}`.trim() ===
        `${material.componentName}`
    );

    let base = data.some(
      (item: any) =>
        `${item.manufacture} ${item.model}`.trim() ===
        `${material.manufacturer}`
    );

    if (full && partial && !base) {
      return true;
    } else {
      return false;
    }
  };
  const certsFinishChecker = () => {
    var data = materials.filter((material: any) => {
      return (
        material?.totalTempCertificate === 0 &&
        material?.ranProducts &&
        getBaseValue(material.ranProducts, material)
      );
    });
    if (data) {
      // our base logic will implement here //
      data &&
        data.forEach((item) => {
          handleStartScrapingManufacture(item);
        });
    }
  };

  const getRohsFiles = async (id: number) => {
    let data: any = await roHSAPI.getFiles(id);
    console.log(data, "data get here");

    setRoHSFiles(data.data)
  }

  const handleStartScrapingManufacture = async (material: any) => {
    try {
      let obj = {
        materialId: materialId,
        search: "",
        manufacturer: material.manufacturer,
        type: material.type,
        fullModelName: material.componentName,
        cutModelName: "",
      };
      await scrappingFetcher([obj]).then((val) => {
        handleRanProductsStartSingle({
          model: obj.search,
          manufacture: obj.manufacturer,
          ranProducts: material.ranProducts,
          id: material.id,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleRanProductsStartSingle = async (product: any) => {
    let data;
    if (product.ranProducts) {
      data = JSON.parse(product.ranProducts);
      let isFind = false;
      for (const item of data) {
        if (
          item.model === product.model &&
          item.manufacture === product.manufacture
        ) {
          isFind = true;
          break;
        }
      }

      if (!isFind) {
        data.push({
          model: product.model,
          manufacture: product.manufacture,
        });
        data = JSON.stringify(data);
      } else {
        data = JSON.stringify(data);
      }
    } else {
      data = JSON.stringify([
        {
          model: product.model,
          manufacture: product.manufacture,
        },
      ]);
    }
    await handleRanProducts(product.id, data);
  };

  const stopLoading = () => {
    wsCertificates.map((wsItem: any) => {
      // let nIndex = isScrappingLoading.findIndex(
      //   (i) => i.materialId === wsItem.materialId
      // );
      // let temp = [...isScrappingLoading];
      // temp[nIndex].loading = false;
      // setIsScrappingLoading(temp);
    });
  };

  const concatShowingMaterials = useCallback(
    (materials: any[]) => {
      let res = materials?.map((material, index) => {
        const materialsArray = material?.certificates?.concat(
          wsCertificates.filter((item: any) => item.materialId === material.id)
        );

        return { ...material, materialsArray };
      });
      if (criticalFilter === "asc") {
        return res.sort((item: any) => (!item.critical ? 1 : -1));
      }
      if (criticalFilter === "desc") {
        return res.sort((item: any) => (!item.critical ? -1 : 1));
      }
      return res;
    },
    [materials, wsCertificates, criticalFilter]
  );

  function filterAndRemoveDuplicates(data: any) {
    const uniqueMaterials: any = {};

    for (const material of data) {
      const key = `${material.manufacturer}-${material.componentName}-${material.type}`;

      if (!uniqueMaterials[key]) {
        uniqueMaterials[key] = material;
      }
    }

    // Convert the uniqueMaterials object back to an array of unique materials
    const uniqueMaterialsArray = Object.values(uniqueMaterials);

    return uniqueMaterialsArray;
  }


  useEffect(() => {
    if (showingData)
      sortingRecord();
  }, [internalComponentsFilter, modelNameFilter, typeFilter, criticalFilter, certificateFilter, tempCertsFilter, manufactureFilter])

  useEffect(() => {
    // let materialData = concatShowingMaterials(materials);
    // let sortedData = filterAndRemoveDuplicates(materialData);
    // let updatedData = referenceData(sortedData);
    // setFilteredMaterials(updatedData);
    // let updatedData1 = updatedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    // setShowingData(updatedData1);
    // setPageCountValue(updatedData.length);
    setValue()
  }, [])

  const setValue = () => {
    let materialData = concatShowingMaterials(materials);
    let sortedData = filterAndRemoveDuplicates(materialData);
    let updatedData = referenceData(sortedData);
    setFilteredMaterials(updatedData);
    let updatedData1 = updatedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    setShowingData(updatedData1);
    setPageCountValue(updatedData.length);
  }
  useEffect(() => {
    if (filteredMaterials) {
      let tempList = [];
      if (searchMaterials.length > 0) {
        tempList = searchMaterials;
      }
      else {
        tempList = filteredMaterials;
      }
      let updatedData = tempList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
      setShowingData(updatedData);
      console.log(updatedData, "showingData calling");
    }
  }, [currentPage]);

  const clearFilters = (clickedFilter: string) => {
    if (clickedFilter === 'certificateFilter') {
      setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setManufactureFilter(false);
      setTypeFilter(false);
      setCriticalFilter(false);
      setTempCertsFilter(false);
    } else if (clickedFilter === 'manufactureFilter') {
      setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setTypeFilter(false);
      setCriticalFilter(false);
      setCertificateFilter(false);
      setTempCertsFilter(false);
    } else if (clickedFilter === 'tempCertsFilter') {
      setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setManufactureFilter(false);
      setTypeFilter(false);
      setCriticalFilter(false);
      setCertificateFilter(false);
    }
    else if (clickedFilter === 'internalComponentsFilter') {
      // setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setManufactureFilter(false);
      setTypeFilter(false);
      setCriticalFilter(false);
      setCertificateFilter(false);
      setTempCertsFilter(false);
    }
    else if (clickedFilter === 'modelNameFilter') {
      setInternalComponentsFilter(false);
      // setModelNameFilter(false);
      setManufactureFilter(false);
      setTypeFilter(false);
      setCriticalFilter(false);
      setCertificateFilter(false);
      setTempCertsFilter(false);
    }
    else if (clickedFilter === 'typeFilter') {
      setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setManufactureFilter(false);
      // setTypeFilter(false);
      setCriticalFilter(false);
      setCertificateFilter(false);
      setTempCertsFilter(false);
    }
    else if (clickedFilter === 'criticalFilter') {
      setInternalComponentsFilter(false);
      setModelNameFilter(false);
      setManufactureFilter(false);
      setTypeFilter(false);
      // setCriticalFilter(false);
      setCertificateFilter(false);
      setTempCertsFilter(false);
    }
  };
  const sortingRecord = () => {
    let tempList = []
    if (searchMaterials.length > 0) {
      tempList = searchMaterials
    } else {
      tempList = filteredMaterials;
    }
    let sortedData = tempList.sort((a: any, b: any) => {

      if (criticalFilter) {
        if (!a.critical) {
          return 1;
        }
        if (!b.critical) {
          return 1;
        }
      }
      if (certificateFilter) {
        if (certificateFilter == SortingOrder.desc) {
          return (b.materialsArray?.length + (isRoHSAllowed ? b.totalRohsCerts : 0) == 0 ? 0 : (b.materialsArray?.length + (isRoHSAllowed ? b.totalRohsCerts : 0))) -
            (a.materialsArray?.length + (isRoHSAllowed ? a.totalRohsCerts : 0) == 0 ? 0 : (a.materialsArray?.length + (isRoHSAllowed ? a.totalRohsCerts : 0)));
        } else {
          return (a.materialsArray?.length + (isRoHSAllowed ? a.totalRohsCerts : 0) == 0 ? 0 : (a.materialsArray?.length + (isRoHSAllowed ? a.totalRohsCerts : 0))) - (b.materialsArray?.length + (isRoHSAllowed ? b.totalRohsCerts : 0) == 0 ? 0 : (b.materialsArray?.length + (isRoHSAllowed ? b.totalRohsCerts : 0)));

        }
      }
      if (tempCertsFilter) {
        if (tempCertsFilter == SortingOrder.desc) {
          return b.totalTempCertificate - a.totalTempCertificate;
        } else {
          return a.totalTempCertificate - b.totalTempCertificate;
        }
      }
      if (manufactureFilter) {

        console.log(manufactureFilter, "manufacture filter");

        if (manufactureFilter == SortingOrder.desc) {

          return b.manufacturer?.toLowerCase() > a.manufacturer?.toLowerCase() ? 1
            : -1;


        } else {
          return b.manufacturer?.toLowerCase() < a.manufacturer?.toLowerCase() ? 1
            : -1
        }
      }
      if (modelNameFilter) {
        if (modelNameFilter == SortingOrder.desc) {

          return b.componentName?.toLowerCase() > a.componentName?.toLowerCase() ? 1
            : -1;
        } else {
          return b.componentName?.toLowerCase() < a.componentName?.toLowerCase() ? 1
            : -1
        }
      }
      if (internalComponentsFilter) {

        if (internalComponentsFilter == SortingOrder.desc) {

          return b.internalReference?.toLowerCase() > a.internalReference?.toLowerCase() ? 1
            : -1;
        } else {
          return b.internalReference?.toLowerCase() < a.internalReference?.toLowerCase() ? 1
            : -1
        }
      }
      if (typeFilter) {
        if (typeFilter == SortingOrder.desc) {
          return b.type?.toLowerCase() > a.type?.toLowerCase() ? 1
            : -1;
        } else {
          return b.type?.toLowerCase() < a.type?.toLowerCase() ? 1
            : -1
        }
      }

    });
    sortedData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    let updatedData = referenceData(sortedData);
    setShowingData(updatedData);
  }

  const referenceData = (sortedData: any) => {
    type ReferenceMapping = { [key: string]: any[] };
    const referenceMapping: ReferenceMapping = {};

    sortedData.forEach((data: any) => {
      const { internalReference } = data;
      if (!referenceMapping[internalReference]) {
        referenceMapping[internalReference] = [];
      }
      referenceMapping[internalReference].push(data);
    });
    console.log(sortedData, 'sortedDatasortedDatasortedData')
    let updatedData: any[] = sortedData
      .filter((data: any, index: number, self: any) => {
        if (data.internalReference === '' || data.internalReference === null) {
          return true; // Include items with empty internal reference
        } else {
          return index === self.findIndex((d: any) => d.internalReference === data.internalReference);
        }
      })
      .map((data: any) => ({
        ...data,
        alternative: (referenceMapping[data.internalReference] || []).filter(
          (item) => (item.id !== data.id && data.internalReference !== '' && data.internalReference !== null && data.internalReference !== undefined)
        ),
        showAlternative: false,
      }));
    return updatedData;
  }

  const showPagesCount = (pageCount: number, currentPage: number) => {
    let array: number[] = Array.from(
      new Set([
        1,
        2,
        3,
        currentPage < 2 ? 1 : currentPage - 1,
        currentPage,
        currentPage >= pageCount ? pageCount : currentPage + 1,
        pageCount - 2,
        pageCount - 1,
        pageCount,
      ])
    )
      .sort((a, b) => a - b)
      .filter((el) => el > 0 && el <= pageCount);
    for (let i = 1; i < array.length; i++) {
      if (array[i] - 1 !== array[i - 1]) {
        array = [...array.slice(0, i), 0, ...array.slice(i)];
        i++;
      }
    }

    return array;
  };

  const handleChangeCritical = async (id: number, value: boolean) => {
    const res = await productApi.updateMaterialCriticalStatus(id, value);
    mutate();
  };

  enum SortingOrder {
    "desc" = "desc",
    "asc" = "asc"
  }

  let tableHeadings = [
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("internalComponentsFilter");
        setInternalComponentsFilter((prevState: any) =>
          prevState === "asc" ? "desc" : "asc"
        );
      }}
    >
      INTERNAL COMPONENT REFERENCE
      {internalComponentsFilter &&
        (internalComponentsFilter === "asc" ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>,
    "DESIGNATOR",
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("modelNameFilter");
        setModelNameFilter((prevState: any) =>
          prevState === "asc" ? "desc" : "asc"
        );
      }}
    >
      MANUFACTURER’S COMPONENT NAME
      {modelNameFilter &&
        (modelNameFilter === "asc" ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>
    ,
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("manufactureFilter");
        setManufactureFilter((prevState: any) =>
          prevState === "asc" ? "desc" : "asc"
        );
      }}
    >
      MANUFACTURER
      {manufactureFilter &&
        (manufactureFilter === "asc" ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>,

    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("typeFilter");
        setTypeFilter((prevState: any) =>
          prevState === "asc" ? "desc" : "asc"
        );
      }}
    >
      TYPE
      {typeFilter &&
        (typeFilter === "asc" ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>,
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("criticalFilter");
        setCriticalFilter((prevState: any) =>
          prevState === "asc" ? "desc" : "asc"
        );
      }}
    >
      CRITICAL
      {criticalFilter &&
        (criticalFilter === "asc" ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>,
   
    // "CERTIFICATES",
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("certificateFilter");
        setCertificateFilter((prevState: any) =>
          prevState === SortingOrder.asc ? SortingOrder.desc : SortingOrder.asc
        );
      }}
    >
      Certification Records
      {certificateFilter &&
        (certificateFilter === SortingOrder.asc ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>
    ,
    <button
      className="px-6 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900 flex hover:text-black"
      onClick={() => {
        clearFilters("tempCertsFilter");
        setTempCertsFilter((prevState: any) =>
          prevState === SortingOrder.asc ? SortingOrder.desc : SortingOrder.asc
        );
      }}
    >
      Temp certificates
      {tempCertsFilter &&
        (tempCertsFilter === SortingOrder.asc ? (
          <ChevronDownIcon className="h-4" />
        ) : (
          <ChevronUpIcon className="h-4" />
        ))}
    </button>,
    "CERTIFICATE STATUS",
    // OCT-18 2023  ROHS code commented because client not want it right now
    // "RoHS CONTACT",
    // "DECLARATIONS",
    // "ROHS STATUS",
    "DELETE",
    "Scraping",
    "COMMENTS",
    "CERTIFICATE CONTACT",
  ];

  const insertTempHeading = (arr: any[], index: number, newItem: string) => {
    return [...arr.slice(0, index), newItem, ...arr.slice(index)];
  };

  const handleAddIdToDelete = (id: number) => {
    if (materialsToDelete.indexOf(id) < 0) {
      return setMaterialsToDelete((prev: number[]) => [...prev, id]);
    } else
      return setMaterialsToDelete((prev: number[]) =>
        prev.filter((item) => item !== id)
      );
  };

  const renderTableInput = (
    value: string | undefined,
    type: string,
    id: number
  ) => {
    if (isEditMode) {
      return <EditMaterial currentValue={value} type={type} id={id} />;
    } else return value;
  };

  const handleRanProductsStart = async (products: any) => {
    let data: any;
    let item1 = products[0];
    if (item1.ranProducts) {
      var ranProducts = JSON.parse(item1.ranProducts);
      ranProducts.forEach((ranProduct: any) => {
        if (
          ranProduct.model === item1.search &&
          ranProduct.manufacture === item1.manufacturer
        ) {
          ranProduct.isRunning = true;
        } else if (
          ranProduct.model === item1.search &&
          ranProduct.manufacture === ""
        ) {
          ranProduct.isRunning = true;
        }
      });
      data = ranProducts;
    } else {
      data = [
        {
          model: item1.search,
          manufacture: item1.manufacturer,
          isRunning: true,
        },
        {
          model: item1.search,
          manufacture: "",
          isRunning: true,
        },
      ];
    }

    await handleRanProducts(products[0].materialId, JSON.stringify(data));
  };

  const handleRanProducts = async (id: number, value: string) => {
    const res = await productApi.updateMaterialRanProducts(id, value);
    mutate();
  };
  // Start 2 Automatic Search
  const handleStartScraping = async (material: any, index: number) => {
    material.manufacturer = material.manufacturer;
    toast.info("Scraping is started!");
    try {
      let data = [];
      let obj = {
        materialId: material.id,
        search: material.componentName,
        manufacturer: material.manufacturer,
        manufactureCopy: material.manufacturer,
        type: material.type,
        fullModelName: material.componentName,
        cutModelName: material.componentName,
        productId: productId,
      };
      data.push(obj);
      obj = { ...obj, manufacturer: "" };
      data.push(obj);
      await scrappingFetcher(data);
      data.forEach((item) => {
        Object.assign(item, { ranProducts: material.ranProducts });
      });

      await handleRanProductsStart(data);
    } catch (e) {
      console.log(e);
    }
  };

  const runChecker = (material: any) => {
    if (material.ranProducts) {
      let data = JSON.parse(material.ranProducts);
      let item1 = data.some(
        (item: any) =>
          item.model === material.componentName &&
          item.manufacture === material.manufacturer &&
          item.isRunning === true
      );
      let item2 = data.some(
        (item: any) =>
          item.model === material.componentName &&
          item.manufacture === "" &&
          item.isRunning === true
      );
      if (item1 && item2) {
        mainPageLoader(true);
      } else {
        mainPageLoader(false);
      }
      return item1 && item2;
    }
    return false;
  };
  const sendEmail = async () => {
    setEmailTextModal({ ...emailTextModal, loader: true });
    var ccMails = `${emails.ccEmails.join(",")}`;

    const bodyData = {
      user: data,
      emailCollection: {
        manufacturer: emailTextModal.manufacturer,
        model: emailTextModal.model,
        customerText: previewText,
        subject: emailTextModal.isRoHSModel
          ? "RoHS Certificate Request"
          : "Supplier Certificate Request",
        receiver: emails.receiverEmail,
        ccEmails: ccMails,
      },
    };

    emailToManufacturer(bodyData)
      .then(async (response: any) => {
        if (response.result === true) {
          updateEmailText(
            emailTextModal.materialId,
            emailTextModal.isRoHSModel ? "isRohsSent" : "isEmail",
            true
          ).then(
            (_) => {
              updateEmailText(
                emailTextModal.materialId,
                emailTextModal.isRoHSModel ? "rohsMailText" : "emailText",
                "Contacting Manufacturer"
              );
              setEmailTextModal({
                ...emailTextModal,
                customerPopup: false,
                confirmEmailPopup: false,
                loader: false,
                customerText: "",
                manufacturer: "",
                model: "",
              });
              setEmails({
                errorText: "",
                emailText: "",
                receiverEmail: receiverEmails.receiver,
                ccEmails: receiverEmails.ccEmails,
              });
              toast.success("Email sent to manufacturer");
            },
            (error) => {
              console.log(error.text);
            }
          );
        } else {
          setEmailTextModal({
            ...emailTextModal,
            customerPopup: false,
            confirmEmailPopup: false,
            loader: false,
            customerText: "",
            manufacturer: "",
            model: "",
          });
          toast.error(
            "Oops! An error occurred while sending the email. Please try again later."
          );
        }
      })
      .catch((e) => {
        console.log(e, "email sending error");
      });
  };

  const emailPreview = () => {
    if (!emails.receiverEmail) {
      toast.error("Please enter recipient email address");
    } else {
      let filterEmails = emails.ccEmails.filter((email: any) => {
        // Check if email does not contain any spaces
        return !email.includes(" ");
      });
      let receiverEmailWithoutSpace = emails.receiverEmail.replace(/\s/g, "");
      setEmails({
        ...emails,
        receiverEmail: receiverEmailWithoutSpace,
        ccEmails: filterEmails,
      });

      setEmailTextModal({
        ...emailTextModal,
        confirmEmailPopup: true,
      });
    }
  };
  const EmailOnChange = (e: any) => {
    setEmails({ ...emails, emailText: e.target.value });
  };
  const addMoreCCEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emails.emailText);
    if (emails.emailText && isValidEmail) {
      const copyCCEmails: any = [...emails.ccEmails];
      copyCCEmails.push(emails.emailText);
      setEmails({
        ...emails,
        errorText: "",
        ccEmails: copyCCEmails,
        emailText: "",
      });
    } else if (!emails.emailText) {
      setEmails({ ...emails, errorText: "Please enter email address" });
    } else {
      setEmails({ ...emails, errorText: "Please enter correct email address" });
    }
  };
  const addRecipient = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emails.emailText);
    if (emails.emailText && isValidEmail) {
      setEmails({
        ...emails,
        receiverEmail: emails.emailText,
        errorText: "",
        emailText: "",
      });
    } else if (!emails.emailText) {
      setEmails({ ...emails, errorText: "Please enter email address" });
    } else {
      setEmails({ ...emails, errorText: "Please enter correct email address" });
    }
  };
  const removeCCEmail = (index: number) => {
    const copyCCEmails = [...emails.ccEmails];
    copyCCEmails.splice(index, 1);
    setEmails({ ...emails, ccEmails: copyCCEmails });
  };
  const removeRecipient = () => {
    setRecipientEditMode(false);
    setEmails({ ...emails, receiverEmail: "" });
  };
  const editRecipient = () => {
    setRecipientEditMode(true);
    setEmails({ ...emails, emailText: emails.receiverEmail });
  };
  const updateRecipient = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emails.emailText);
    if (emails.emailText && isValidEmail) {
      setEmails({
        ...emails,
        receiverEmail: emails.emailText,
        emailText: "",
        errorText: "",
      });
      setRecipientEditMode(false);
    } else if (!emails.emailText) {
      setEmails({ ...emails, errorText: "Please enter email address" });
    } else {
      setEmails({ ...emails, errorText: "Please enter correct email address" });
    }
  };
  // this will return only one mail address against one material name
  const fetchManufactureMails = async (name: string) => {
    let payload = {
      name: name,
    };
    let resMails = await getManufactureMails(payload);

    if (resMails.result == "success" && resMails.data) {
      return resMails.data.address;
    }
  };
  useEffect(() => {
    getAllMaterialMailsAddresses();
  }, []);

  const viewDataSheet = () => {
    const datasheetURL = digiKeyURL;
    if (datasheetURL && datasheetURL.includes("cert/digiKey")) {
      const origin = window.location.origin;
      const finalURL = origin + datasheetURL;
      window.open(finalURL, "_blank");
    } else if (datasheetURL) {
      window.open(datasheetURL, "_blank");
    }
  }

  // this will return many mails addresses against  product materials names
  const getAllMaterialMailsAddresses = async () => {
    let materialsName = materials.map((material) => material.manufacturer);
    const payload = {
      names: materialsName,
    };
    let response = await manufacturerMails(payload);
    setAllMaterialsManufacturerName(response?.data);
  };

  const isMailExist = (manufactureName: any) => {
    let isAddress = allMaterialsManufacturerName?.find(
      (material: any) => material.name == manufactureName
    );
    if (isAddress) {
      return false;
    } else {
      return true;
    }
  };

  const roshContactLabel = (material: any) => {
    let mailAddress = isMailExist(material.manufacturer);
    if (mailAddress == true || material.rohsStatus == 7) {
      return "Pending";
    } else if (material.rohsMailText != "") {
      return material.rohsMailText;
    } else {
      return "Email Manufacturer";
    }
  };
  const rohsContactDisability = (material: any) => {
    let mailAddress = isMailExist(material.manufacturer);
    if (!material.isRohsSent && material.rohsMailText.includes("Waiting")) {
      return true;
    } else if (material.isRohsSent) {
      return true;
    } else if (mailAddress == true || material.rohsStatus == 7) {
      return true;
    } else {
      return false;
    }
  };
  const updateEmailText = async (id: number, type: any, value: any) => {
    const res = await materialAPI.updateMaterialValues(id, value, type);

    if (type == "emailText") {
      console.log(type, "testing  type of");
      if (materialStatus) {
        if (value === "No Certificates Found") {
          updateMaterialStatusApiCall(
            emailTextModal.materialId,
            materialStatus[0]
          );
        } else {
          // when email to manufacture
          updateMaterialStatusApiCall(
            emailTextModal.materialId,
            materialStatus[2]
          );
        }
      }
    } else if (type == "rohsMailText") {
      console.log(type, "testing  type of mau");
      // await materialAPI.updateMaterialValues(id,"rohsMailText","Contacting Manufacturer");
      await materialAPI.updateMaterialValues(id, 3, "rohsStatus");
    }
    await mutate();
    return res;
  };
  async function updateMaterialStatusApiCall(materialId: number, status: any) {
    let materialStatusBody = {
      status: status,
    };
    await updateMaterialStatus(materialId, materialStatusBody);
    mutate();
  }
  const emailDropdown = () => {
    return (
      <div className="h-auto px-12 py-3 flex flex-col gap-5 text-2xl font-medium mb-2.5 text-primary-500">
        <label htmlFor="" className="">
          Contact label
        </label>

        <div className="flex flex-col items-end gap-5">
          <select
            className="border border-gray-300 rounded text-gray-500"
            defaultValue={emailTextModal.selectedText}
            onChange={(event) =>
              setEmailTextModal({
                ...emailTextModal,
                selectedText: event.target.value,
              })
            }
          >
            <option value="Contacting Manufacture" className="text-gray-500">
              Contacting Manufacture
            </option>
            <option value="No Certificates Found" className="text-gray-500">
              No Certificates Found
            </option>
          </select>
          <Button
            label="Update"
            onClick={() =>
              updateEmailText(
                emailTextModal.materialId,
                emailTextModal.isRoHSModel ? "rohsMailText" : "emailText",
                emailTextModal.selectedText
              ).then((res) => {
                if (emailTextModal.isRoHSModel) {
                  updateEmailText(
                    emailTextModal.materialId,
                    emailTextModal.isRoHSModel ? "isRohsSent" : "isEmail",
                    false
                  );
                  materialAPI.updateMaterialValues(
                    emailTextModal.materialId,
                    1,
                    "rohsStatus"
                  );
                }
                setEmailTextModal({
                  ...emailTextModal,
                  adminPopup: false,
                  selectedText: "Contacting Manufacturer",
                });
              })
            }
          />
        </div>
      </div>
    );
  };

  const rohsEmailDropdown = () => {
    return (
      <div className="h-auto px-12 py-3 flex flex-col gap-5 text-2xl font-medium mb-2.5 text-primary-500">
        <label htmlFor="" className="">
          Contact label
        </label>

        <div className="flex flex-col items-end gap-5">
          <select
            className="border border-gray-300 rounded text-gray-500"
            defaultValue={emailTextModal.selectedText}
            onChange={(event) =>
              setEmailTextModal({
                ...emailTextModal,
                selectedText: event.target.value,
              })
            }
          >
            <option value="Contacting Manufacture" className="text-gray-500">
              Contacting Manufacture
            </option>
            <option value="No Certificates Found" className="text-gray-500">
              No Certificates Found
            </option>
          </select>
          <Button
            label="Update"
            onClick={() =>
              updateEmailText(
                emailTextModal.materialId,
                emailTextModal.isRoHSModel ? "rohsMailText" : "emailText",
                emailTextModal.selectedText
              ).then((res) => {
                if (emailTextModal.isRoHSModel) {

                  updateEmailText(
                    emailTextModal.materialId,
                    emailTextModal.isRoHSModel ? "isRohsSent" : "isEmail",
                    false
                  );
                  materialAPI.updateMaterialValues(emailTextModal.materialId, 1, "rohsStatus");

                }
                setEmailTextModal({
                  ...emailTextModal,
                  adminPopup: false,
                  selectedText: "Contacting Manufacturer",
                });
              })
            }
          />
        </div>
      </div>
    );
  };
  const onUploadHandler = async () => {
    try {
      setDigiKeyLoading(true);
      const form_data = new FormData();
      if (digiKeyFile !== null) {
        form_data.append("file", digiKeyFile);
        form_data.append("id", idDigiKeyToUpload.toString());

        await fetcher({
          key: "digikey/upload",
          data: form_data,
        });
      }
      await mutate();
      setDigiKeyLoading(false);
      setIdDigiKeyToUpload(false);
      setIdDigiKeyToUpdate(false);
      // closeModal();
      toast.success("File Uploaded!");
    } catch (e) {
      toast.error("Something went wrong");
      setDigiKeyLoading(false);
      setIdDigiKeyToUpload(false);
      setIdDigiKeyToUpdate(false);
    }
  };
  const customerRequireCertificatePopup = () => {
    return (
      <>
        <div className="px-12 py-3  w-42rem">
          <p className=" text-2xl font-medium mb-2.5 text-primary-500 ">
            {`Request ${emailTextModal.isRoHSModel ? "RoHS" : "certificate"
              } documentation from manufacturer by email`}
          </p>
          <div className="flex flex-col gap-4 ">
            <Input
              className="text-red-300"
              value={emails.emailText}
              placeholder="Optional: Add custom e-mail address"
              errorText={emails.errorText && emails.errorText}
              onChange={(e) => {
                EmailOnChange(e);
              }}
            />
            <div className="flex justify-end gap-2">
              {recipientEditMode && (
                <Button
                  label="Update Main Recipient"
                  onClick={() => updateRecipient()}
                />
              )}
              {!emails.receiverEmail && !recipientEditMode && (
                <Button
                  label="Add Main Recipient"
                  onClick={() => addRecipient()}
                />
              )}
              {!recipientEditMode && (
                <Button label="Add CC" onClick={() => addMoreCCEmail()} />
              )}
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <label htmlFor="" className="Input__label">
              Receivers
            </label>
            <div className="flex flex-col bg-gray-100 p-2 gap-2 rounded-md  max-w-xl overflow-y-scroll max-h-40">
              <div className="flex flex-col">
                <p className="Input__label">Recipient</p>
                {emails.receiverEmail && (
                  <div className="relative w-min">
                    <p
                      onClick={() => {
                        editRecipient();
                      }}
                      className="bg-white rounded-md shadow px-2 py-1 mr-3 mt-2 text-sm hover: cursor-pointer"
                    >
                      {emails.receiverEmail}
                    </p>

                    <div
                      onClick={() => removeRecipient()}
                      className="flex justify-center absolute -top-1 -right-1 rounded-full bg-gray-200 w-6 h-6 items-center hover: cursor-pointer"
                    >
                      <img
                        width={10}
                        height={10}
                        src={closeIcon}
                        alt="close icon"
                      />
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <p className="Input__label">CC</p>
              <div className="flex max-w-xl flex-wrap gap-y-3 gap-x-2">
                {emails.ccEmails.map((email, index) => {
                  return (
                    <>
                      <div className="relative w-min">
                        <p
                          key={index}
                          className="bg-white rounded-md shadow px-2 py-1 mr-3 mt-2 text-sm"
                        >
                          {email}
                        </p>

                        {email == emails.ccEmails[0] ||
                          email == emails.ccEmails[1] ? (
                          <div></div>
                        ) : (
                          <div
                            onClick={() => removeCCEmail(index)}
                            className="flex justify-center absolute -top-1 -right-1 rounded-full bg-gray-200 w-6 h-6 items-center hover: cursor-pointer"
                          >
                            <img
                              width={10}
                              height={10}
                              src={closeIcon}
                              alt="close icon"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500">
            What type of certificate on this component do you require
          </h3> */}
          <div className={"flex flex-col mt-6"}>
            <Textarea
              placeholder={`You can add extra details`}
              // value={emailTextModal.customerText}
              defaultValue={emailTextModal.customerText}
              onChange={(e) =>
                setEmailTextModal({
                  ...emailTextModal,
                  customerText: e.target.value,
                })
              }
              name="message"
            />
            <input
              type="hidden"
              name="client_name"
              value={data.firstName + " " + data.lastName}
            />
            <input type="hidden" name="client_email" value={data.email} />
            <div className={"ml-auto mt-4"}>
              <Button
                label="Preview"
                onClick={() => {
                  emailPreview();
                }}
                loading={emailTextModal.loader}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  let previewText = "";
  const textFormator = (text: string) => {
    text = text
      .replaceAll(
        emailDetails?.firstName,
        `<strong>${emailDetails?.firstName}</strong>`
      )
      .replaceAll(
        emailDetails?.lastName,
        `<strong>${emailDetails?.lastName}</strong>`
      )
      .replaceAll(
        emailDetails?.model,
        `<strong>${emailDetails?.model}</strong>`
      )
      .replaceAll(
        emailDetails?.manufacture,
        `<strong>${emailDetails?.manufacture}</strong>`
      )
      .replaceAll("\n", "<br>");
    previewText = text;
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
  };
  const emailMessageConfirmation = () => {
    return (
      <div className="max-w-3xl max-h-128 overflow-y-scroll p-3">
        <h3 className="text-2xl text-center leading-10 font-medium mb-2.5 text-primary-500">
          Email Message Preview
        </h3>
        <p className="text-gray-500 flex text-justify flex-wrap break-words px-6 mb-2 ">
          {textFormator(emailTextModal.customerText)}
        </p>
        {/* signature section */}
        <div className="px-6 w-96">
          <div>
            <p className="text-primary_color text-2xl font-medium">
              Johan Rågmark
            </p>
            <p className="text-gray-500 flex text-justify flex-wrap break-words  mb-2 ">
              Certification and Product Specialist
            </p>
            <p className="text-gray-500 flex text-justify flex-wrap break-words  mb-2 ">
              Certainli
            </p>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "#1b463d",
            }}
          ></div>
          <div className="mt-2">
            <ul className="flex flex-col gap-2">
              <li className="flex gap-5 items-center">
                <img src={receiverPhone} width={20} alt="phoneIcon" />
                <a
                  className="underline text-gray-500 flex text-justify flex-wrap break-words mb-2"
                  href="tel:+460734351666"
                >
                  +46 (0) 734 35 16 66
                </a>
              </li>
              <li className="flex gap-5 items-center">
                <img src={message} width={20} alt="messageIcon" />
                <a
                  className="underline text-gray-500 flex text-justify flex-wrap break-words mb-2"
                  href="mailto:johan@certain.li"
                >
                  johan@certain.li
                </a>
              </li>
              <li className="flex gap-5 items-center">
                <img src={link} width={20} alt="phoneIcon" />
                <a
                  className="underline text-gray-500 flex text-justify flex-wrap break-words mb-2"
                  href="https://certain.li"
                  target="blank"
                >
                  certain.li
                </a>
              </li>
              <li className="flex gap-5 items-center">
                <img src={location} width={20} alt="phoneIcon" />
                <p className="text-gray-500 flex text-justify flex-wrap break-words">
                  Wallingatan 12,111 60, Stockholm
                </p>
              </li>
            </ul>
          </div>
          <div
            style={{
              marginTop: "5px",
              height: "1px",
              backgroundColor: "#1b463d",
              marginBottom: "5px",
            }}
          ></div>
          <img
            src="https://dev.certain.li/static/media/certainli-logo.26a6559e.png"
            width={200}
            alt="logo"
          />
        </div>
        <div className=" flex gap-6 justify-end items-end">
          <Button
            label="Cancel"
            color={BUTTON_COLORS.normal}
            onClick={() => {
              setEmailTextModal({
                ...emailTextModal,
                confirmEmailPopup: false,
              });
            }}
          />
          <Button
            label="Send Mail"
            // onClick={()=>console.log('confirm click')}
            onClick={() => sendEmail()}
            loading={emailTextModal.loader}
          />
        </div>
      </div>
    );
  };
  const emailToManufacturerClick = async (
    material: any,
    isRoHS: boolean = false
  ) => {
    console.log(isRoHS, "this is my");

    let customEmailText = `Dear Sir/Madam,  \n
On behalf of ${data?.firstName} ${data?.lastName}, at ${data?.company?.name ? data?.company?.name : "Company"
      }, who is developing a product in which the component ${material.componentName
      } from you at ${material.manufacturer
      } is considered as a critical component in an electrical safety testing, we are requesting component documentation for this. This includes listing numbers and/or test certificates for electrical/mechanical safety, etc., performed by approved Certification Bodies.   \n
Thank you for your attention to this matter.
`;

    let customRoHSText = `Dear Sir/Madam,\nWe are requesting RoHS declaration on behalf of ${data?.firstName
      } ${data?.lastName}, at ${data?.company?.name ? data?.company?.name : "Company"
      }, ${data?.company?.name ? data?.company?.name : "Company"
      } are using or intends to use your products  manufactured by ${material.manufacturer
      }.\nWe are requesting RoHS declaration(s) for the following product ${material.componentName
      }\n If we are reaching out to an incorrect email address for this purpose, please reply to us or forward this to the proper recipient.\nThank you for your attention to this matter.\nBest Regards,
    `;

    setEmailDetails({
      firstName: data?.firstName,
      lastName: data?.lastName,
      company: data?.company?.name ?? "",
      model: material.componentName,
      manufacture: material.manufacturer,
    });

    setManufactureMails(true);

    await fetchManufactureMails(material.manufacturer).then((address) => {
      setManufactureMails(false);
      if (address) {
        setEmails({ ...emails, receiverEmail: address });
      }
      if (isAdmin && material.isEmail && !isRoHS) {
        setEmailTextModal({
          ...emailTextModal,
          materialId: material.id,
          adminPopup: true,
          address: address,
          selectedText: material.emailText ?? material.emailText,
          model: material.componentName,
          isRoHSModel: isRoHS,
          manufacturer: material.manufacturer,
          customerText: isRoHS ? customRoHSText : customEmailText,
        });
      } else if (isAdmin && material.isRohsSent && isRoHS) {
        setEmailTextModal({
          ...emailTextModal,
          materialId: material.id,
          adminPopup: true,
          address: address,
          selectedText: material.rohsMailText,
          model: material.componentName,
          isRoHSModel: isRoHS,
          manufacturer: material.manufacturer,
          customerText: isRoHS ? customRoHSText : customEmailText,
        });
      } else {
        setEmailTextModal({
          ...emailTextModal,
          isRoHSModel: isRoHS,
          customerPopup: true,
          address: address,
          materialId: material.id,
          model: material.componentName,
          manufacturer: material.manufacturer,
          customerText: isRoHS ? customRoHSText : customEmailText,
        });
      }
    });
  };

  const componentStatusOnChange = (e: any, materialId: number) => {
    console.log(e.target.value, "component on change", "mat id", materialId);
    console.log(materialStatus, "mmm");

    const filteredComponentStatus = materialStatus?.filter(
      (status: any) => status.id == e.target.value
    );
    console.log(filteredComponentStatus[0], "filter");
    updateMaterialStatusApiCall(materialId, filteredComponentStatus[0]);
  };

  const rohsComponentStatusOnChange = async (
    e: any,
    materialId: number,
    material: any
  ) => {
    console.log(e.target.value);

    if (ROHS_COMPONENT_STATUSES.FLAGGED == e.target.value) {
      setSelectedMaterial(material);

      // sendFlagMail(material);
    } else {
      await materialAPI.updateMaterialValues(
        materialId,
        e.target.value,
        "rohsStatus"
      );
      await mutate();
    }
  };

  const createFlaggedView = () => {
    return (
      <>
        <div>
          <h3 className="text-2xl leading-10 font-medium mb-2.5 text-primary-500 mx-10">
            Are You Sure?
          </h3>
        </div>
        <h3 className="mx-10 text-2xs leading-10 mb-2.5 text-gray-500">
          It will send mail to Admin related this flagged Component
        </h3>
        <div className={"flex flex-col"}>
          <div className={"flex ml-auto mt-4"}>
            <Button
              color="primary"
              label={"Cancel"}
              onClick={() => {
                setFlagMailLoading(false);
                setSelectedMaterial(null);
              }}
            />

            <Button
              color="primary"
              className="ml-3 mb-5 mr-5"
              label={"Confirm"}
              loading={flagMailLoading}
              onClick={() => {
                sendFlagMail(selectedMaterial);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  let sendFlagMail = async (material: any) => {
    let customRoHSText = `Dear Sir/Madam,  \n
    On behalf of <b>${data?.firstName} ${data?.lastName}</b>, at ${data?.company?.name ? data?.company?.name : "Company"
      }, They want to Flag this Component
        <b>${material.componentName}</b>
         from you at 
         <b>${material.manufacturer}</b> and the url is ${window.location.href
      }     
    Thank you for your attention to this matter.
    `;
    try {
      setFlagMailLoading(true);
      let res = await flagMailNotification({
        id: material.id,
        mailInfo: {
          ccMails: receiverEmails.ccEmails,
          htmlContent: customRoHSText,
        },
      });

      if (res.result) {
        toast.success("Mails Sent Successfully!");
      }
      await mutate();
      setFlagMailLoading(false);
      setSelectedMaterial(null);
    } catch (error) {
      setFlagMailLoading(false);
      setSelectedMaterial(null);
      toast.error("Something Went Wrong!");
    }
  };

  const showAlternativeHandler = (id: string, index: number) => {
    let updatedArray = showingData.map((val: any) => {
      if (val.id === id) {
        return { ...val, showAlternative: true }
      } else {
        return val
      }
    })
    setShowingData(updatedArray);
  };


  const hideAlternativeHandler = (id: string) => {
    let updatedArray = showingData.map((val: any) => {
      if (val.id === id) {
        return { ...val, showAlternative: false }
      } else {
        return val
      }
    })
    setShowingData(updatedArray);
  }
  return (
    <>
      {manufactureMails ? (
        <Loader />
      ) : (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="table-auto divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeadings.map((heading, index) => {
                          if (
                            !isAdmin &&
                            (
                              index === 7 ||
                              index === 9 ||
                              heading === "Scraping" ||
                              heading === "DELETE")
                          )
                            return null;
                          return (
                            <th
                              key={index}
                              scope="col"
                              className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                            >
                              {heading}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {showingData &&
                        showingData.map((material: any, index: any) => {
                          return (
                            <>
                              <tr
                                key={material.id}
                                className={
                                  index % 2 === 0 ? undefined : "bg-gray-50"
                                }
                              >
                                {/* <td className={`whitespace-nowrap px-3 py-3 align-top text-sm font-medium ${
    material?.showAlternative ? 'text-black-500' : 'text-gray-500'
  } sm:pl-6 cursor-pointer`}  onClick={() =>
    material?.showAlternative
      ?hideAlternativeHandler(material.id) 
      : showAlternativeHandler(material.id, index)
  }> */}
                                {/* {material.showAlternative !== undefined &&
                                material.showAlternative === false ? (
                                  <ChevronRightIcon
                                    className="h-7 w-7"
                                   
                                  />
                                ) : material.showAlternative === true? (
                                  <ChevronDownIcon
                                    className="h-7 w-7"
                                   
                                  />
                                ) : null} */}
                                {/* </td> */}

                                <td className="whitespace-nowrap px-3 py-3 flex  align-top text-sm font-medium text-gray-500 sm:pl-6 "
                                  onClick={() =>
                                    material?.showAlternative
                                      ? hideAlternativeHandler(material.id)
                                      : showAlternativeHandler(material.id, index)
                                  }
                                >
                                  {material.alternative.length > 0
                                    && material.showAlternative !== undefined &&
                                    material.showAlternative === false ? (
                                    <ChevronRightIcon
                                      className="h-5 w-5 cursor-pointer"

                                    />
                                  ) : material.alternative.length > 0 && material.showAlternative === true ? (
                                    <ChevronDownIcon
                                      className="h-5 w-5 cursor-pointer"

                                    />
                                  ) : <div className="h-5 w-5"></div>}

                                  <div
                                    // className="w-40"
                                    className={styles.manufacturerTooltipWrap}
                                  >
                                    {
                                      isEditMode ? <div className="truncate">
                                        {renderTableInput(
                                          material?.internalReference,
                                          "internalReference",
                                          material.id
                                        )}
                                      </div> : <Tooltip
                                        color={"#333332"}
                                        direction={"up-start"}
                                        content={
                                          <div
                                            className={
                                              "text-white rounded p-2 font-light"
                                            }
                                            style={{
                                              backgroundColor: "#333332",
                                            }}
                                          >
                                            {material?.internalReference}
                                          </div>
                                        }
                                      >
                                        <div className="truncate">
                                          {renderTableInput(
                                            material?.internalReference,
                                            "internalReference",
                                            material.id
                                          )}
                                        </div>

                                      </Tooltip>
                                    }

                                  </div>
                                </td>

                                <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-500 sm:pl-6"

                                >
                                  {material?.designator.length > 0
                                    && material?.designator !== undefined &&
                                    material?.designator === false ? (
                                    <ChevronRightIcon
                                      className="h-5 w-5 cursor-pointer"

                                    />
                                  ) : material?.designator.length > 0 && material?.designator === true ? (
                                    <ChevronDownIcon
                                      className="h-5 w-5 cursor-pointer"

                                    />
                                  ) : <div className="h-5 w-5"></div>}

                                  <div
                                    // className="w-40"
                                    //  className="truncate"
                                    className={styles.manufacturerTooltipWrap}
                                  >

                                    {
                                      !material.designator ? "" :
                                        <Tooltip
                                          color={"#333332"}
                                          direction={"up-start"}
                                          content={

                                            <div
                                              className={
                                                "text-white rounded p-2 font-light"
                                              }
                                              style={{
                                                backgroundColor: "#333332",
                                              }}
                                            >
                                              {JSON.parse(material?.designator).join()}
                                            </div>
                                          }
                                        >

                                          <div style={{
                                            maxWidth: "200px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}>
                                            {
                                              JSON.parse(material?.designator).join()
                                            }
                                          </div>


                                        </Tooltip>
                                    }

                                  </div>
                                </td>

                                {/* <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'                                      
                                  }`}>
                                  <div className="flex flex-col" >
                               
                                    {
                                       !material?.designator?<></>:
                                       <p>
                                        {
                                          JSON.parse(material?.designator).join()
                                        }
                                       </p>
                                    // renderTableInput(
                                    //   JSON.parse(material?.designator).join(),
                                    //   "designator",
                                    //   material.id
                                    // )
                                  }
                                 
                                  </div>
                                </td> */}





                                <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                  }`}>
                                  <div className="flex flex-col">

                                    {renderTableInput(
                                      material?.componentName,
                                      "componentName",
                                      material.id
                                    )}
                                    {/* // <a href={material?.datasheetURL.includes("cert/digiKey") ? window.location.origin + material?.datasheetURL : material?.datasheetURL} className="underline pt-2 pointer" target="_blank">See Datasheet</a> */}
                                    {material?.datasheetURL ?

                                      <div className="underline pt-2 cursor-pointer" onClick={() => {
                                        setIdDigiKeyToUpdate(material.id);
                                        setDigiKeyURL(material.datasheetURL);
                                      }}>
                                        See Datasheet
                                      </div>
                                      :
                                      <div className="underline pt-2 cursor-pointer" onClick={() => {
                                        setIdDigiKeyToUpload(material.id);

                                      }}>Upload Datasheet</div>}
                                  </div>
                                </td>
                                <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                  }`}>
                                  {isEditMode ? (
                                    renderTableInput(
                                      material?.manufacturer,
                                      "manufacturer",
                                      material.id
                                    )
                                  ) : (
                                    <div
                                      className={styles.manufacturerTooltipWrap}
                                    >
                                      <Tooltip
                                        color={"#333332"}
                                        direction={"up-start"}
                                        content={
                                          <div
                                            className={
                                              "text-white rounded p-2 font-light"
                                            }
                                            style={{
                                              backgroundColor: "#333332",
                                            }}
                                          >
                                            {material?.manufacturer}
                                          </div>
                                        }
                                      >
                                        <p className={styles.manufacturerName}>
                                          {material?.manufacturer}
                                        </p>
                                      </Tooltip>
                                    </div>
                                  )}
                                </td>
                                <td className={`whitespace-nowrap px-3 py-3 align-top text-sm ${material?.showAlternative ? 'text-black-500' : 'text-gray-500'
                                  }`}>
                                  {renderTableInput(
                                    material?.type,
                                    "type",
                                    material.id
                                  )}
                                </td>

                                {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <Button
                                  label="Approve Component Certificates"
                                  onClick={() =>
                                    materialStatus &&
                                    updateMaterialStatusApiCall(
                                      material?.id,
                                      materialStatus[1]
                                    )
                                  }
                                  disabled={
                                    material.materialStatus?.id ===
                                      COMPONENT_STATUSES.APPROVED ||
                                    material.materialStatus?.id ===
                                      COMPONENT_STATUSES.WAITING_FOR_REPLY
                                  }
                                />
                              </td> */}

                                <td className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900">
                                  {material?.critical}
                                  <CriticalCheckbox
                                    id={material.id}
                                    handleChangeCritical={handleChangeCritical}
                                    value={material.critical}
                                    disable={
                                      productStatus &&
                                      productStatus?.id ===
                                      CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                                    }
                                  />
                                </td>
                               

                                {
                                  // !isAdmin && 
                                  <td
                                    className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500"
                                    style={{ minWidth: 170 }}
                                  >
                                    <div className={styles.certificatesWrapper}>
                                      {(
                                        <Button
                                          loading={rohsLoader}
                                          color={"primary"}
                                          label={`View ${(material.materialsArray?.length + (isRoHSAllowed ? material.totalRohsCerts : 0) == 0 ? "" : (material.materialsArray?.length + (isRoHSAllowed ? material.totalRohsCerts : 0)))}`}
                                          onClick={async () => {
                                            if (isRoHSAllowed) {
                                              setRohsLoader(true);
                                              await getRohsFiles(material.id)
                                              setRohsLoader(false);
                                            }
                                            setManufactureName(
                                              material.manufacturer
                                            );
                                            setRoHSCertificatesPath(
                                              material.rohsLocalPath
                                            );
                                            setRoHSCollectionDate(
                                              material.rohsCollectionDate
                                            );
                                            setRoHSCertificateApprove(
                                              material.rohsStatus ==
                                              ROHS_COMPONENT_STATUSES.APPROVED
                                            );
                                            setCertificateType(material.type);
                                            // setRoHSCertificatesID(material.id);
                                            setMaterialCertificatesName(
                                              material?.componentName
                                            );
                                            setMaterialCertificatesID(material.id);

                                            setRequiredCertificates(
                                              material.requiredCertificates
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </td>
                                }

                                {isAdmin ? (
                                  <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                    <MatchScoreColumn
                                      productID={productId}
                                      ranProducts={material.ranProducts}
                                      manufacture={material.manufacturer}
                                      type={material.type}
                                      variations={material.variations}
                                      materialComment={material.materialComment}
                                      fullModelName={material?.componentName}
                                      totalTempCertificates={
                                        material.totalTempCertificate
                                      }
                                      materialId={material.id}
                                      mutate={mutate}
                                      wsCertificates={wsCertificates}
                                    />
                                  </td>
                                ) : null}
                                <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  {
                                    <select
                                      className={styles.componentSelect}
                                      // disabled={
                                      //   COMPONENT_STATUSES.WAITING_FOR_REPLY ===
                                      //   material?.materialStatus?.id
                                      // }
                                      onChange={(e) =>
                                        componentStatusOnChange(e, material?.id)
                                      }
                                      name="componentStatus"
                                      id="component-status"
                                    >
                                      <option
                                        className="text-gray-300"
                                        value={COMPONENT_STATUSES.IN_REVIEW}
                                        selected={
                                          COMPONENT_STATUSES.IN_REVIEW ===
                                          material?.materialStatus?.id
                                        }
                                      >
                                        In Review
                                      </option>
                                      <option
                                        value={COMPONENT_STATUSES.APPROVED}
                                        selected={
                                          COMPONENT_STATUSES.APPROVED ===
                                          material?.materialStatus?.id
                                        }
                                      >
                                        Approved
                                      </option>
                                      <option
                                        value={
                                          COMPONENT_STATUSES.WAITING_FOR_REPLY
                                        }
                                        selected={
                                          COMPONENT_STATUSES.WAITING_FOR_REPLY ===
                                          material?.materialStatus?.id
                                        }
                                      // disabled
                                      >
                                        {" "}
                                        Waiting for Reply
                                      </option>
                                    </select>
                                  }
                                </td>
                                {/*  // OCT-18 2023  ROHS code commented because client not want it right now */}
                                {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  <Button
                                    color={"primary"}
                                    label={roshContactLabel(material)}
                                    disabled={rohsContactDisability(material)}
                                    onClick={async () => {
                                      emailToManufacturerClick(material, true);
                                    }}
                                  />
                                </td> */}
                                {/* OCT-18 2023  ROHS code commented because client not want it right now */}
                                {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  <div className={styles.certificatesWrapper}>
                                    {!material.rohsLocalPath && !isAdmin ? (
                                      <p></p>
                                    ) : (
                                      <Button
                                        color={"primary"}
                                        label={`View ${material.rohsLocalPath ? 1 : ""
                                          }`}
                                        disabled={
                                          isMailExist(material.manufacturer) ||
                                          ROHS_COMPONENT_STATUSES.PENDING ==
                                          material?.rohsStatus
                                        }
                                        onClick={() => {
                                          setMaterialCertificatesName(
                                            material?.componentName
                                          );
                                          setManufactureName(
                                            material.manufacturer
                                          );
                                          setRoHSCertificatesPath(
                                            material.rohsLocalPath
                                          );
                                          setRoHSCollectionDate(
                                            material.rohsCollectionDate
                                          );
                                          setRoHSCertificateApprove(
                                            material.rohsStatus ==
                                            ROHS_COMPONENT_STATUSES.APPROVED
                                          );
                                          // material.isRohsApprove
                                          setCertificateType(material.type);
                                          setRoHSCertificatesID(material.id);
                                          setRequiredCertificates(
                                            material.requiredCertificates
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                </td> */}
                                {/* ROHS STATUS  */}
                                {/*   // OCT-18 2023  ROHS code commented because client not want it right now */}
                                {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  {
                                    <select
                                      className={styles.componentSelect}
                                      value={material?.rohsStatus}
                                      disabled={
                                        ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY ==
                                        material?.rohsStatus ||
                                        ROHS_COMPONENT_STATUSES.SOON_OUTDATED ==
                                        material.rohsStatus ||
                                        ROHS_COMPONENT_STATUSES.OUTDATED ==
                                        material.rohsStatus ||
                                        isMailExist(material.manufacturer) ||
                                        ROHS_COMPONENT_STATUSES.PENDING ==
                                        material?.rohsStatus
                                      }
                                      onChange={(e) =>
                                        rohsComponentStatusOnChange(
                                          e,
                                          material?.id,
                                          material
                                        )
                                      }
                                      name="componentStatus"
                                      id="component-status"
                                    >
                                      <option
                                        className="text-gray-300"
                                        value={
                                          ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY
                                        }
                                        selected={
                                          ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY ==
                                          material?.rohsStatus
                                        }
                                        disabled
                                      >
                                        Waiting for reply
                                      </option>
                                      <option
                                        value={ROHS_COMPONENT_STATUSES.IN_REVIEW}
                                        selected={
                                          ROHS_COMPONENT_STATUSES.IN_REVIEW ==
                                          material?.rohsStatus
                                        }
                                      >
                                        In Review
                                      </option>
                                      <option
                                        value={ROHS_COMPONENT_STATUSES.APPROVED}
                                        selected={
                                          ROHS_COMPONENT_STATUSES.APPROVED ==
                                          material?.rohsStatus
                                        }
                                      >
                                        {" "}
                                        Approved
                                      </option>
                                      <option
                                        value={ROHS_COMPONENT_STATUSES.FLAGGED}
                                        selected={
                                          ROHS_COMPONENT_STATUSES.FLAGGED ==
                                          material?.rohsStatus
                                        }
                                      >
                                        {" "}
                                        Flagged
                                      </option>
                                      <option
                                        value={
                                          ROHS_COMPONENT_STATUSES.SOON_OUTDATED
                                        }
                                        selected={
                                          ROHS_COMPONENT_STATUSES.SOON_OUTDATED ==
                                          material?.rohsStatus
                                        }
                                        disabled
                                      >
                                        {" "}
                                        Soon outdated
                                      </option>
                                      <option
                                        value={ROHS_COMPONENT_STATUSES.OUTDATED}
                                        selected={
                                          ROHS_COMPONENT_STATUSES.OUTDATED ==
                                          material?.rohsStatus
                                        }
                                        disabled
                                      >
                                        {" "}
                                        Outdated
                                      </option>
                                      <option
                                        value={ROHS_COMPONENT_STATUSES.PENDING}
                                        selected={
                                          isMailExist(material.manufacturer) ||
                                          ROHS_COMPONENT_STATUSES.PENDING ==
                                          material?.rohsStatus
                                        }
                                        disabled
                                      >
                                        Pending
                                      </option>
                                    </select>
                                  }
                                </td> */}
                                {isAdmin && (
                                  <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500 text-center">
                                    <input
                                      className="text-green-600 focus:ring-green-600"
                                      type={"checkbox"}
                                      checked={
                                        materialsToDelete.indexOf(material.id) >=
                                        0
                                      }
                                      onChange={() =>
                                        handleAddIdToDelete(material.id)
                                      }
                                    />
                                  </td>
                                )}
                                {isAdmin ? (
                                  <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500 text-center">
                                    <Button
                                      label="Start"
                                      loading={runChecker(material)}
                                      onClick={() =>
                                        handleStartScraping(material, index)
                                      }
                                    />
                                  </td>
                                ) : null}
                                 <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  <Button
                                    // color={"primary"}
                                    color={material?.commentEntity?.length > 0 ? "primary" : BUTTON_COLORS.normal}
                                    label={"Show"}
                                    onClick={() =>
                                      setCommentsModalShown(material.id)
                                    }
                                  />
                                </td>

                                <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                  <Button
                                    // color={"primary"}
                                    color={BUTTON_COLORS.normal}
                                    label={
                                      material.isEmail
                                        ? material.emailText
                                        : "Email Manufacturer"
                                    }
                                    disabled={
                                      material.materialStatus?.id ===
                                        COMPONENT_STATUSES.APPROVED
                                        ? true
                                        : isAdmin
                                          ? false
                                          : !isAdmin && material.isEmail
                                    }
                                    onClick={async () => {
                                      emailToManufacturerClick(material);
                                    }}
                                  />
                                </td>
                              </tr>
                              {/* -========-================-==================-=-====================------------------==================== */}
                              {/* {material?.alternative.map((subVal:any,ind:number)=>{ */}
                              {material?.showAlternative && material.alternative.length > 0 && (
                                material.alternative.map((subVal: any, ind: number) => {

                                  return (
                                    <tr
                                      key={subVal.id}
                                      className={
                                        index % 2 === 0 ? undefined : "bg-gray-50"
                                      }
                                    >
                                      {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm font-medium text-gray-500 sm:pl-6 cursor-pointer">
                               
                              </td> */}

                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm font-medium text-gray-500 sm:pl-6">
                                        {/* {renderTableInput(
                                  subVal?.internalReference,
                                  "internalReference",
                                  subVal.id
                                )} */}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm font-medium text-gray-500 sm:pl-6">
                                        {renderTableInput(
                                  subVal?.designator
                                  ,
                                  "designator",
                                  subVal.id
                                )}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">

                                        {renderTableInput(
                                          subVal?.componentName,
                                          "componentName",
                                          subVal.id
                                        )}

                                        {subVal?.datasheetURL ?

                                          <div className="underline pt-2 cursor-pointer" onClick={() => {
                                            setIdDigiKeyToUpdate(subVal.id);
                                            setDigiKeyURL(subVal.datasheetURL);
                                          }}>
                                            See Datasheet
                                          </div>
                                          :
                                          <div className="underline pt-2 cursor-pointer" onClick={() => {
                                            setIdDigiKeyToUpload(subVal.id);

                                          }}>Upload Datasheet</div>}


                                      </td>
                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        {isEditMode ? (
                                          renderTableInput(
                                            subVal?.manufacturer,
                                            "manufacturer",
                                            subVal.id
                                          )
                                        ) : (
                                          <div
                                            className={styles.manufacturerTooltipWrap}
                                          >
                                            <Tooltip
                                              color={"#333332"}
                                              direction={"up-start"}
                                              content={
                                                <div
                                                  className={
                                                    "text-white rounded p-2 font-light"
                                                  }
                                                  style={{
                                                    backgroundColor: "#333332",
                                                  }}
                                                >
                                                  {subVal?.manufacturer}
                                                </div>
                                              }
                                            >
                                              <p className={styles.manufacturerName}>
                                                {subVal?.manufacturer}
                                              </p>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        {renderTableInput(
                                          subVal?.type,
                                          "type",
                                          subVal.id
                                        )}
                                      </td>

                                      {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                <Button
                                  label="Approve Component Certificates"
                                  onClick={() =>
                                    materialStatus &&
                                    updateMaterialStatusApiCall(
                                      material?.id,
                                      materialStatus[1]
                                    )
                                  }
                                  disabled={
                                    material.materialStatus?.id ===
                                      COMPONENT_STATUSES.APPROVED ||
                                    material.materialStatus?.id ===
                                      COMPONENT_STATUSES.WAITING_FOR_REPLY
                                  }
                                />
                              </td> */}

                                      <td className="whitespace-nowrap px-3 py-3 align-top text-center text-sm text-gray-500">
                                        {subVal?.critical}
                                        <CriticalCheckbox
                                          id={subVal.id}
                                          handleChangeCritical={handleChangeCritical}
                                          value={subVal.critical}
                                          disable={
                                            productStatus &&
                                            productStatus?.id ===
                                            CERTIFICATE_COLLECTION_STATUSES.READY_FOR_REVIEW
                                          }
                                        />
                                      </td>
                                 

                                      <td
                                        className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500"
                                        style={{ minWidth: 300 }}
                                      >
                                        <div className={styles.certificatesWrapper}>
                                          {(
                                            // (
                                            //   <>
                                            //     {material.materialsArray
                                            //       .slice(0, 2)
                                            //       .map((certificat: any) => {
                                            //         return (
                                            //           <div key={certificat.id}>
                                            //             <span
                                            //               className={"flex text-gray-900"}
                                            //             >
                                            //               <FileIcon className="mr-2" />{" "}
                                            //               <span
                                            //                 className={
                                            //                   styles.certManufacturerName
                                            //                 }
                                            //               >
                                            //                 {material?.manufacturer}
                                            //               </span>
                                            //               {certificat.id}
                                            //             </span>

                                            //             <a
                                            //               href={certificat.source}
                                            //               target={"_blank"}
                                            //               className={
                                            //                 "text-sm leading-5 font-medium"
                                            //               }
                                            //             >
                                            //               View certificate
                                            //             </a>
                                            //           </div>
                                            //         );
                                            //       })}
                                            //     <div
                                            //       className={"text-center cursor-pointer"}
                                            //       onClick={() =>
                                            //         setMaterialCertificatesID(material.id)
                                            //       }
                                            //     >
                                            //       View all
                                            //     </div>
                                            //   </>
                                            // )
                                            <>
                                              {(
                                                <Button
                                                  loading={rohsLoader}
                                                  color={"primary"}
                                                  label={`View ${(subVal.materialsArray?.length + (isRoHSAllowed ? subVal.totalRohsCerts : 0) == 0 ? "" : (subVal.materialsArray?.length + (isRoHSAllowed ? subVal.totalRohsCerts : 0)))}`}
                                                  onClick={async () => {
                                                    if (isRoHSAllowed) {
                                                      setRohsLoader(true);
                                                      await getRohsFiles(subVal.id)
                                                      setRohsLoader(false);
                                                    }
                                                    setManufactureName(
                                                      subVal.manufacturer
                                                    );
                                                    setRoHSCertificatesPath(
                                                      subVal.rohsLocalPath
                                                    );
                                                    setRoHSCollectionDate(
                                                      subVal.rohsCollectionDate
                                                    );
                                                    setRoHSCertificateApprove(
                                                      subVal.rohsStatus ==
                                                      ROHS_COMPONENT_STATUSES.APPROVED
                                                    );
                                                    setCertificateType(subVal.type);
                                                    // setRoHSCertificatesID(material.id);
                                                    setMaterialCertificatesName(
                                                      subVal?.componentName
                                                    );
                                                    setMaterialCertificatesID(subVal.id);

                                                    setRequiredCertificates(
                                                      subVal.requiredCertificates
                                                    );
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      {isAdmin ? (
                                        <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                          <MatchScoreColumn
                                            productID={productId}
                                            materialComment={materialComment}
                                            ranProducts={subVal.ranProducts}
                                            manufacture={subVal.manufacturer}
                                            type={subVal.type}
                                            variations={subVal.variations}
                                            fullModelName={subVal?.componentName}
                                            totalTempCertificates={
                                              subVal.totalTempCertificate
                                            }
                                            materialId={subVal.id}
                                            mutate={mutate}
                                            wsCertificates={wsCertificates}
                                          />
                                        </td>
                                      ) : null}
                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        {
                                          <select
                                            className={styles.componentSelect}
                                            // disabled={
                                            //   COMPONENT_STATUSES.WAITING_FOR_REPLY ===
                                            //   material?.materialStatus?.id
                                            // }
                                            onChange={(e) =>
                                              componentStatusOnChange(e, subVal?.id)
                                            }
                                            name="componentStatus"
                                            id="component-status"
                                          >
                                            <option
                                              className="text-gray-300"
                                              value={COMPONENT_STATUSES.IN_REVIEW}
                                              selected={
                                                COMPONENT_STATUSES.IN_REVIEW ===
                                                subVal?.materialStatus?.id
                                              }
                                            >
                                              In Review
                                            </option>
                                            <option
                                              value={COMPONENT_STATUSES.APPROVED}
                                              selected={
                                                COMPONENT_STATUSES.APPROVED ===
                                                subVal?.materialStatus?.id
                                              }
                                            >
                                              Approved
                                            </option>
                                            <option
                                              value={
                                                COMPONENT_STATUSES.WAITING_FOR_REPLY
                                              }
                                              selected={
                                                COMPONENT_STATUSES.WAITING_FOR_REPLY ===
                                                subVal?.materialStatus?.id
                                              }
                                            // disabled
                                            >
                                              {" "}
                                              Waiting for Reply
                                            </option>
                                          </select>
                                        }
                                      </td>
                                      {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        <Button
                                          color={"primary"}
                                          label={roshContactLabel(subVal)}
                                          disabled={rohsContactDisability(subVal)}
                                          onClick={async () => {
                                            emailToManufacturerClick(subVal, true);
                                          }}
                                        />
                                      </td> */}
                                      {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        <div className={styles.certificatesWrapper}>
                                          {!subVal.rohsLocalPath && !isAdmin ? (
                                            <p></p>
                                          ) : (
                                            <Button
                                              color={"primary"}
                                              label={`View ${subVal.rohsLocalPath ? 1 : ""
                                                }`}
                                              disabled={
                                                isMailExist(subVal.manufacturer) ||
                                                ROHS_COMPONENT_STATUSES.PENDING ==
                                                subVal?.rohsStatus
                                              }
                                              onClick={() => {
                                                setMaterialCertificatesName(
                                                  subVal?.componentName
                                                );
                                                setManufactureName(
                                                  subVal.manufacturer
                                                );
                                                setRoHSCertificatesPath(
                                                  subVal.rohsLocalPath
                                                );
                                                setRoHSCollectionDate(
                                                  subVal.rohsCollectionDate
                                                );
                                                setRoHSCertificateApprove(
                                                  subVal.rohsStatus ==
                                                  ROHS_COMPONENT_STATUSES.APPROVED
                                                );
                                                // material.isRohsApprove
                                                setCertificateType(subVal.type);
                                                setRoHSCertificatesID(subVal.id);
                                                setRequiredCertificates(
                                                  subVal.requiredCertificates
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </td> */}
                                      {/* ROHS STATUS  */}
                                      {/* <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        {
                                          <select
                                            className={styles.componentSelect}
                                            value={subVal?.rohsStatus}
                                            disabled={
                                              ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY ==
                                              subVal?.rohsStatus ||
                                              ROHS_COMPONENT_STATUSES.SOON_OUTDATED ==
                                              subVal.rohsStatus ||
                                              ROHS_COMPONENT_STATUSES.OUTDATED ==
                                              subVal.rohsStatus ||
                                              isMailExist(material.manufacturer) ||
                                              ROHS_COMPONENT_STATUSES.PENDING ==
                                              subVal?.rohsStatus
                                            }
                                            onChange={(e) =>
                                              rohsComponentStatusOnChange(
                                                e,
                                                subVal?.id,
                                                subVal
                                              )
                                            }
                                            name="componentStatus"
                                            id="component-status"
                                          >
                                            <option
                                              className="text-gray-300"
                                              value={
                                                ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY
                                              }
                                              selected={
                                                ROHS_COMPONENT_STATUSES.WAITING_FOR_REPLY ==
                                                subVal?.rohsStatus
                                              }
                                              disabled
                                            >
                                              Waiting for reply
                                            </option>
                                            <option
                                              value={ROHS_COMPONENT_STATUSES.IN_REVIEW}
                                              selected={
                                                ROHS_COMPONENT_STATUSES.IN_REVIEW ==
                                                subVal?.rohsStatus
                                              }
                                            >
                                              In Review
                                            </option>
                                            <option
                                              value={ROHS_COMPONENT_STATUSES.APPROVED}
                                              selected={
                                                ROHS_COMPONENT_STATUSES.APPROVED ==
                                                subVal?.rohsStatus
                                              }
                                            >
                                              {" "}
                                              Approved
                                            </option>
                                            <option
                                              value={ROHS_COMPONENT_STATUSES.FLAGGED}
                                              selected={
                                                ROHS_COMPONENT_STATUSES.FLAGGED ==
                                                subVal?.rohsStatus
                                              }
                                            >
                                              {" "}
                                              Flagged
                                            </option>
                                            <option
                                              value={
                                                ROHS_COMPONENT_STATUSES.SOON_OUTDATED
                                              }
                                              selected={
                                                ROHS_COMPONENT_STATUSES.SOON_OUTDATED ==
                                                subVal?.rohsStatus
                                              }
                                              disabled
                                            >
                                              {" "}
                                              Soon outdated
                                            </option>
                                            <option
                                              value={ROHS_COMPONENT_STATUSES.OUTDATED}
                                              selected={
                                                ROHS_COMPONENT_STATUSES.OUTDATED ==
                                                subVal?.rohsStatus
                                              }
                                              disabled
                                            >
                                              {" "}
                                              Outdated
                                            </option>
                                            <option
                                              value={ROHS_COMPONENT_STATUSES.PENDING}
                                              selected={
                                                isMailExist(material.manufacturer) ||
                                                ROHS_COMPONENT_STATUSES.PENDING ==
                                                subVal?.rohsStatus
                                              }
                                              disabled
                                            >
                                              Pending
                                            </option>
                                          </select>
                                        }
                                      </td> */}
                                      {isAdmin && (
                                        <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500 text-center">
                                          <input
                                            className="text-green-600 focus:ring-green-600"
                                            type={"checkbox"}
                                            checked={
                                              materialsToDelete.indexOf(subVal.id) >=
                                              0
                                            }
                                            onChange={() =>
                                              handleAddIdToDelete(subVal.id)
                                            }
                                          />
                                        </td>
                                      )}
                                      {isAdmin ? (
                                        <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500 text-center">
                                          <Button
                                            label="Start"
                                            loading={runChecker(subVal)}
                                            onClick={() =>
                                              handleStartScraping(subVal, index)
                                            }
                                          />
                                        </td>
                                      ) : null}
                                         <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        <Button
                                          color={material?.commentEntity?.length > 0 ? "primary" : BUTTON_COLORS.normal}
                                          label={"Show"}
                                          onClick={() =>
                                            setCommentsModalShown(subVal.id)
                                          }
                                        />
                                      </td>

                                      <td className="whitespace-nowrap px-3 py-3 align-top text-sm text-gray-500">
                                        <Button
                                          color={BUTTON_COLORS.normal}
                                          label={
                                            subVal.isEmail
                                              ? subVal.emailText
                                              : "Email Manufacturer"
                                          }
                                          disabled={
                                            subVal.materialStatus?.id ===
                                              COMPONENT_STATUSES.APPROVED
                                              ? true
                                              : isAdmin
                                                ? false
                                                : !isAdmin && subVal.isEmail
                                          }
                                          onClick={async () => {
                                            emailToManufacturerClick(subVal);
                                          }}
                                        />
                                      </td>
                                    </tr>



                                  )
                                }))}

                            </>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="flex-1 flex justify-between sm:hidden">
                      <button
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() =>
                          setCurrentPage((currentPage) => currentPage - 1)
                        }
                      >
                        Previous
                      </button>
                      <button
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        onClick={() =>
                          setCurrentPage((currentPage) => currentPage + 1)
                        }
                      >
                        Next
                      </button>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{" "}
                          <span className="font-medium">
                            {(currentPage - 1) * itemsPerPage + 1}
                          </span>{" "}
                          to{" "}
                          <span className="font-medium">
                            {currentPage !== pageCount
                              ? currentPage * itemsPerPage
                              :
                              //  materials?.length
                              pageCountValue
                            }
                          </span>{" "}
                          of{" "}
                          <span className="font-medium">
                            {pageCountValue
                              //  materials?.length
                            }
                          </span>{" "}
                          results
                        </p>
                      </div>
                      <div>
                        <nav
                          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          <button
                            onClick={() =>
                              setCurrentPage((currentPage) => currentPage - 1)
                            }
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>

                          {showPagesCount(pageCount, currentPage).map(
                            (elem, ind) => {
                              if (elem === 0) {
                                return (
                                  <div
                                    key={elem}
                                    aria-current="page"
                                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                                  >
                                    ...
                                  </div>
                                );
                              }
                              return (
                                <button
                                  key={elem}
                                  onClick={() => setCurrentPage(elem)}
                                  aria-current="page"
                                  className={
                                    elem === currentPage
                                      ? styles.activeBtn
                                      : styles.defaultBtn
                                  }
                                >
                                  {elem}
                                </button>
                              );
                            }
                          )}

                          <button
                            disabled={pageCount === currentPage}
                            onClick={() =>
                              setCurrentPage((currentPage) => currentPage + 1)
                            }
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!materialCertificatesID && !rohsLoader && (
            <CustomModal
              isShown={!!materialCertificatesID}
              setModalShown={() => setMaterialCertificatesID(false)}
            >
              <MaterialCertificatesList
                getRohsFiles={getRohsFiles}
                rohsFiles={rohsFiles}
                collectionDate={rohsCollectionDate}
                rohsApprove={rohsCertificateApprove}
                rohsLocalPath={roHSCertificatesPath}
                type={certificateType}
                manufactureName={manufactureName}
                componentName={materialCertificatesName}
                requiredCertificates={requiredCertificates}
                materialCertificatesID={materialCertificatesID}
                productId={productId}
                closeModal={() => setMaterialCertificatesID(false)}
              />
            </CustomModal>
          )}
          {!!rohsCertificatesID && (
            <CustomModal
              isShown={!!rohsCertificatesID}
              setModalShown={() => setRoHSCertificatesID(false)}
            >
              <RoHSCertificatesList
                componentName={materialCertificatesName}
                manufactureName={manufactureName}
                type={certificateType}
                requiredCertificates={requiredCertificates}
                materialCertificatesID={rohsCertificatesID}
                productId={productId}
                collectionDate={rohsCollectionDate}
                setRoHSCollectionDate={setRoHSCollectionDate}
                rohsApprove={rohsCertificateApprove}
                rohsLocalPath={roHSCertificatesPath}
                closeModal={() => setRoHSCertificatesID(false)}
              />
            </CustomModal>
          )}
          {isCommentsModalShown && (
            <CustomModal
              isShown={!!isCommentsModalShown}
              setModalShown={() => setCommentsModalShown(null)}
            >
              <MaterialComments id={isCommentsModalShown} />
            </CustomModal>
          )}
          <CustomModal
            setModalShown={() => setMaterialId(false)}
            isShown={!!materialId}
          >
            <AddCertificate
              materialId={materialId}
              mutate={mutate}
              closeModal={() => setMaterialId(false)}
            />
          </CustomModal>
          {/* update email manufacturer button text by admin */}
          <CustomModal
            isShown={emailTextModal.adminPopup}
            setModalShown={() =>
              setEmailTextModal({ ...emailTextModal, adminPopup: false })
            }
          >
            {emailDropdown()}
          </CustomModal>

          <CustomModal
            isShown={isRohsEmailPopUp}
            setModalShown={() =>
              setIsRohsEmailPopUp(!isRohsEmailPopUp)
            }
          >
            {rohsEmailDropdown()}
          </CustomModal>
          {/* taking customer required certificate information */}
          <CustomModal
            isShown={emailTextModal.customerPopup}
            setModalShown={() => {
              setEmails({
                errorText: "",
                emailText: "",
                receiverEmail: receiverEmails.receiver,
                ccEmails: receiverEmails.ccEmails,
              });
              setEmailTextModal({ ...emailTextModal, customerPopup: false });
            }}
          >
            {customerRequireCertificatePopup()}
          </CustomModal>
          <CustomModal
            isShown={emailTextModal.confirmEmailPopup}
            setModalShown={() => {
              setEmails({
                errorText: "",
                emailText: "",
                receiverEmail: emails.receiverEmail ?? "",
                ccEmails: emails.ccEmails,
              });
              setEmailTextModal({
                ...emailTextModal,
                confirmEmailPopup: false,
              });
            }}
          >
            {emailMessageConfirmation()}
          </CustomModal>

          <CustomModal
            isShown={selectedMaterial}
            setModalShown={() => {
              setFlagMailLoading(false);
              setSelectedMaterial(null);
            }}
          >
            {createFlaggedView()}
          </CustomModal>
        </div>
      )}
      <CustomModal
        setModalShown={() => setIdDigiKeyToUpload(false)}
        isShown={!!idDigiKeyToUpload}
      >
        <div className="m-6">
          <div className={styles.addModalWrap}>
            <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500 ">
              Upload Datasheet
            </h2>
            <div className={"mt-6"}>
              <FileUploader
                handleChange={setDigiKeyFile}
                onSizeError={(err: string) => toast.error(err)}
                onTypeError={(err: string) => toast.error(err)}
                name="file"
                classes={"DragDropFiles"}
                types={["pdf"]}
                maxSize={10}
                children={
                  <div className="DragDropFiles__body">
                    <Upload className="DragDropFiles__icon" />
                    <div className="DragDropFiles__body__title">
                      Upload a file <span>or drag and drop</span>
                    </div>
                    <div className="DragDropFiles__body__subtitle">
                      PDF up to 10MB
                    </div>
                  </div>
                }
              />
              { }

              {digiKeyFile ? (
                <div className="AddProduct__fileView">
                  <div className="AddProduct__fileView__left">
                    <Document className="AddProduct__fileView__icon" />
                    <div className="AddProduct__fileView__text">{digiKeyFile?.name}</div>
                  </div>
                  <div
                    className="AddProduct__fileView__right"
                    onClick={() => setDigiKeyFile(null)}
                  >
                    Remove
                  </div>
                </div>
              ) : null}
            </div>
            <div className={"mt-6 flex justify-end"}>
              <Button
                label="Upload"
                disabled={!digiKeyFile || digiKeyLoading}
                onClick={() => onUploadHandler()}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        setModalShown={() => setIdDigiKeyToUpdate(false)}
        isShown={!!idDigiKeyToUpdate}
      >
        <div className="mx-8  mb-8 mt-2">
          <div className={styles.addModalWrap}>
            <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500 flex justify-start mr-32">
              Datasheet
            </h2>

            <div className={"mt-8 flex justify-end gap-3"}>
              <Button
                className=""
                label="Edit"
                onClick={() => setIdDigiKeyToUpload(idDigiKeyToUpdate)}
              />
              <Button
                className=""
                label="View"
                onClick={() => viewDataSheet()}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
