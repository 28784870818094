import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { scrappingUrl } from "../../api/constants";
import { BUTTON_COLORS, Button } from "../button/Button";
import CustomModal from "../custom-modal/CustomModal";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { directMail, fetcher, vdeChecker } from "../../api/SWR/fetchers";
import useProduct from "../../api/SWR/useProduct";
import { certificateAPI, materialAPI, roHSAPI } from "../../api/apiRequests";
import _ from "lodash";
import Loader from "../loader/Loader";
import useUser from "../../api/SWR/useUser";
import EditCertificateField from "../ui/edit-certificate-field/EditCertificateField";
import EditRoHSCertificateField from "../ui/edit-certificate-field/EditRoHSCertificateField";
import AddCertificate from "../add-certificate/AddCertificate";
import axios from "axios";
import { ROHS_COMPONENT_STATUSES } from "../../constants/enums";
import loadingLotifiles from "../../static/assets/lotties/loading.json";
import AddRoHS from "../add-rohs/AddRoHS";
// import Lottie from 'react-lottie';
import AddExisting from "../add-existing/AddExisiting";
import { selectcustomStyles, selectCustomStyles } from "../../constants/selectStyles";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { TRUE } from "sass";
import { boolean } from "yup";

import TextareaWithDropdown from "./seriesnCustomInput";
import EditCertificateCheckbox from "../ui/edit-certificate-field/editeCertificateCheckBox";

// let tableHeadings:Array<string> = ["source", "reference number", "expire date","C-Doc No.", "Delete"];
// let rohsTableHeadings:Array<string> = ["TYPE","ISSUE DATE","C-Doc No.", ];
interface RowData {
  series: string | undefined;
  // Add other fields that are part of your row if needed
}
type DataType = {
  id: number;
  value: string;
};

const MaterialCertificatesList = ({
  getRohsFiles,
  rohsFiles,
  componentName,
  materialCertificatesID,
  productId,
  requiredCertificates,
  rohsApprove,
  rohsLocalPath,
  collectionDate,
  manufactureName,
  type,
  closeModal
}: {
  getRohsFiles: any;
  rohsFiles: any;
  materialCertificatesID: number | boolean;
  componentName: string | undefined;
  productId: number;
  closeModal: () => void;
  requiredCertificates: any;
  collectionDate: string | undefined;
  rohsApprove: boolean;
  rohsLocalPath: string;
  manufactureName: string | undefined;
  type: string | undefined;
}) => {
  const [idCertificateToUpload, setIdCertificateToUpload] = useState<
    boolean | number
  >(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [webLoading, setWebLoading] = useState(false);
  const [isAddExisting, setIsAddExisting] = useState(false);
  const [tableHeadings, settableHeadings] = useState<Array<string>>(["source", "reference number", "expire date", "C-Doc No.", "Confidential", "Delete"])
  const [rohsTableHeadings, setrohsTableHeadings] = useState<Array<string>>(["TYPE", "ISSUE DATE", "C-Doc No.", "MANUFACTURER", "TYPE", "SERIES", "MPN"])
  const [certificatesToDelete, setCertificatesToDelete] = useState<number[]>(
    []
  );
  const [certificatesToConfidential, setCertificatesToConfidential] = useState<number[]>(
    []
  );
  const [certificatesToDeleteRohs, setCertificatesToDeleteRohs] = useState(

  );
  const [isApprove, setIsApprove] = useState(rohsApprove);
  let [selectedType, setSelectedType] = useState<any>([]);
  let [selectedManufacturer, setselectedManufacturer] = useState<any>([]);
  const [rows, setRows] = useState<string[]>(['']);

  const addNewRow = (index: number) => {
    if (rows[index] !== '') {
      setRows([...rows, '']);
    }
  };

  const handleInputChange = (value: string, index: number) => {
    let updatedRows = [...rows];
    updatedRows[index] = value;
    setRows(updatedRows);
  };

  const handleKeyPress = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter') {
      addNewRow(index);
    }
  };


  // let [editedIds, seteditedIds] = useState<Array<number>>([]);

  const [typeOptions, setTypeOptions] = useState<Array<any>>([
    "All",

    "All with exclusions"

  ]);
  let [selectedSeries, setSelectedSeries] = useState<Array<any>>([]);
  let [dbSeries, setdbSeries] = useState<Array<any>>([]);
  // const [seriesOptions, setSeriesOptions] = useState<Array<string>>([
  //   "All",

  //   "All with exclusions"

  // ]);
  let [selectedMPN, setSelectedMPN] = useState<any>([]);


  const [MPNOptions, setMPNOptions] = useState<Array<string>>([
    "All",

    "All with exclusions"

  ]);


  const approveStatus = async () => {
    if (isApprove) {
      await materialAPI.updateMaterialValues(+materialCertificatesID, ROHS_COMPONENT_STATUSES.IN_REVIEW, "rohsStatus")
    } else {
      await materialAPI.updateMaterialValues(+materialCertificatesID, ROHS_COMPONENT_STATUSES.APPROVED, "rohsStatus")
    }
    setIsApprove(!isApprove);
    await mutate();
  }
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [iframeModalData, setIframeModalData] = useState<
    false | { link: string; body: string }
  >(false);

  const { data, mutate, isLoading: dataLoading } = useProduct(productId);
  const [isAddCertificateModalShown, setIsAddCertificateModalShown] =
    useState(false);
  const [isAddRoHSModalShown, setIsAddRoHSModalShown] =
    useState(false);

  const user = useUser();
  const isAdmin = (user.data.role === 1);
  useEffect(() => {
    if (!isAdmin) {
      const tempList1 = tableHeadings.filter((value) => {
        return value !== "C-Doc No." && value !== "Confidential" && value !== "Delete";

      });
      const templist2 = rohsTableHeadings.filter((value) =>
        value !== "C-Doc No." && value !== "MANUFACTURER" && value !== "SERIES" && value !== "MPN"
      );

      settableHeadings(tempList1);
      setrohsTableHeadings(templist2);
    }

  }, [isAdmin])
  // useEffect(() => {
  //   // if(rohsFiles){
  //   for (let i = 0; i < rohsFiles.length; i++) {
  //     var data = rohsFiles[i];
  //     if (data.manufacturer) {
  //       selectedManufacturer[i] = data.manufacturer;
  //       setselectedManufacturer([...selectedManufacturer]);
  //     }
  //     if (data.series) {
  //       selectedSeries[i] = data.series;
  //       setSelectedSeries([...selectedSeries]);
  //     }
  //     if (data.mpn) {
  //       selectedMPN[i] = data.mpn;
  //       setSelectedMPN([...selectedMPN]);
  //     }
  //     if (data.type2) {
  //       selectedType[i] = data.type2;
  //       setSelectedType([...selectedType]);
  //     }
  //     // }

  //   }
  // }, [rohsFiles])
  const isRoHSAllowed = user.data.isRoHSAllow;
  if (dataLoading) {
    return (
      <div className="relative">
        <Loader />
      </div>
    );
  }



  const showingData = data?.materials?.filter(
    (material: any) => material.id === materialCertificatesID
  )?.[0]?.certificates;

  const onUploadHandler = async () => {
    try {
      setIsLoading(true);
      const form_data = new FormData();
      if (file !== null) {
        form_data.append("file", file);
        form_data.append("id", idCertificateToUpload.toString());
        await fetcher({
          key: "certificate/file/upload",
          data: form_data,
        });
      }
      await mutate();
      setIsLoading(false);
      toast.success("Certificate file uploaded!");
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const sendDirectMail = async (cert: any) => {
    try {
      toast.success("Email Sent");
      cert.productId = productId;
      await directMail(cert);
    } catch (e) {
      toast.error("Something went wrong");
    }
  };



  const handleAddCertificateToDelete = (id: number) => {
    if (certificatesToDelete.indexOf(id) < 0) {
      return setCertificatesToDelete((prev: number[]) => [...prev, id]);
    } else
      return setCertificatesToDelete((prev: number[]) =>
        prev.filter((item) => item !== id)
      );
  };
  const handleAddCertificateToConfidential = (id: number) => {
    if (certificatesToConfidential.indexOf(id) < 0) {
      return setCertificatesToConfidential((prev: number[]) => [...prev, id]);
    } else
      return setCertificatesToConfidential((prev: number[]) =>
        prev.filter((item) => item !== id)
      );
  };
  const handleDeleteCertificates = async () => {
    try {
      setIsLoading(true);
      await certificateAPI.delete(certificatesToDelete);
      await mutate();
      toast.success("Deleted!");
      setIsLoading(false);
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const handleChangeEditMaterialMode = async (isEditMode: boolean) => {
    console.log("selected sereis  ====>>>>>>>>", dbSeries);
    // console.log("selected sereis  ====>>>>>>>>", selectedType);
    if (isEditMode) {
      await mutate();
      const payload = [];
      for (let i = 0; i < rohsFiles.length; i++) {
        let data: { [key: string]: any } = {};


        // console.log(selectedType[i]["value"]);
        let inc = 0;
        if (selectedType[i]) {
          data.type2 = selectedType[i]["value"] || selectedType[i];
          inc++;
        }
        let seriesData = dbSeries.filter((item) => item.id == rohsFiles[i].id);

        if (seriesData.length > 0) {
          // console.log("selected sereis  ====>>>>>>>>", rohsFiles[i]);

          // rohsFiles[i].rohs_series
          let tempList: Array<string> = []
          let tempList2: Array<string> = []

          for (let j = 0; j < seriesData.length; j++) {

            tempList2.push(seriesData[j].value)
            const exist = rohsFiles[i].rohs_series.some((item: any) => item.series == seriesData[j].value)
            if (!exist) {
              tempList.push(seriesData[j].value)
            }
          }
          if (tempList.length > 0) {
            data.series = JSON.stringify(tempList2);
            data.isSeriesAdded = [...tempList];
            inc++;
          }

        }
        // else{
        //   data.series=null;
        //   inc++;
        // }
        // if (selectedSeries[i]) {

        //   data.series = selectedSeries[i]["value"];
        //   if (dbSeries[i]) {
        //     if (dbSeries[i].value["value"] == data.series) {
        //       data.isSeriesAdded = true;
        //     }
        //   }

        //   inc++;

        // }
        if (selectedMPN[i]) {
          data.mpn = selectedMPN[i]["value"];
          inc++;

        }
        if (selectedManufacturer[i]) {
          data.manufacturer = selectedManufacturer[i];
          inc++;
        }
        if (inc > 0) {
          data.id = rohsFiles[i].id;
          payload.push(data);

        }
      }
      if (payload.length == 0) {
        setIsEditMode(!isEditMode);
        return;
      }

      try {
        const response = await roHSAPI.updateRohs(payload)
        if (response.status == 201) {

          toast.success(response.data.message);
        }
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', "Error"));
      }
    }
    setIsEditMode(!isEditMode);
  };
  // function hasEditedIds(id: number): void {
  //   const check = editedIds.some((value: number) => {
  //     return value == id;
  //   });
  //   if (!check) {
  //     seteditedIds([...editedIds, id]);
  //   }
  // }
  const handleAddCertificateToDeleteRohs = (id: any) => {
    if (!certificatesToDeleteRohs) {
      setCertificatesToDeleteRohs(id);
    } else {
      setCertificatesToDeleteRohs(undefined)
    }
  };

  const handleDeleteCertificatesRohs = async (id: number) => {
    try {
      setIsLoading(true);
      await materialAPI.updateMaterialValues(id, "", "rohsLocalPath");
      await materialAPI.updateMaterialValues(id, "", "rohsCollectionDate");
      await mutate();
      toast.success("Deleted!");
      setIsLoading(false);
      closeModal();
    } catch (e) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const renderTableInput = (
    value: string | undefined,
    type: string,
    id: number
  ) => {
    if (isEditMode) {
      return <EditCertificateField currentValue={value} type={type} id={id} />;
    } else return value;
  };
  const renderCertificateCheckBox = (
    value: boolean,
    type: string,
    id: number
  ) => {
    // if (isEditMode) {
    return <EditCertificateCheckbox currentValue={value} isEditMode={isEditMode} id={id} type={type} />;
    // } else return value;
  };
  function ManufactureCallback(value: any, index: number) {
    if (value) {
      selectedManufacturer[index] = value;
      setselectedManufacturer(selectedManufacturer);
    }
  }
  const renderRoHSTableInput = (
    value: string | undefined,
    type: string,
    id: number,
    index: number,
  ) => {
    if (isEditMode) {
      return <EditRoHSCertificateField currentValue={value} type={type} id={id} callback={ManufactureCallback} index={index} />;
    } else return value;
  };
  const urlChecker = async (parsedData: any, cert: any) => {
    if (cert.serviceName === "VDE") {
      setWebLoading(true);
      const body = {
        model: cert.modelName ? cert.modelName : '',
        manufacturer: cert.manufacture ? cert.manufacture : '',
        // certificateNumber: cert.referenceNumber ? cert.referenceNumber : (parsedData.certificateNumber ? parsedData.certificateNumber : ''),
        certificateNo: cert?.referenceNumber ?? parsedData?.certificateNumber ?? '',
        // certificateNo: cert.referenceNumber ? cert.referenceNumber : (parsedData.certificateNumber ? parsedData.certificateNumber : ''),
      };
      vdeChecker(body).then((response: any) => {
        if (response.result) {
          window.open(response.url, "_blank");
          setWebLoading(false);
        } else {
          toast.error("Link not work");
          setWebLoading(false);
        }
      });
    } else {
      console.log(cert.serviceName, "service name in else");
      let certNO = cert?.rawData
        ? parsedData.certificateNumber
        : cert.referenceNumber;
      console.log(certNO, "d");

      const form = document.createElement("form");
      form.method = "post";
      form.action =
        "https://certificatedirectory.intertek.se/Home/GetCertificates";
      // parameters of the form
      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "CertificateNumber";
      input1.value = certNO;
      form.appendChild(input1);
      document.body.appendChild(form);
      form.submit();
    }
  };
  const nameChanger = (name: any) => {
    // service name
    // 'UlProductiQ',
    // 'CSA',
    // 'SgsTuevSaar',
    // 'Astabeab',
    // 'Certipedia',
    // 'ETLDirectory',
    // 'ETLEUDirectory',
    // 'SDirectory',
    // 'VDE',
    if (name == 'Certipedia') {
      return 'TÜV'
    }
    else if (name == 'TUV Rheinland') {
      return 'TÜV'
    }

    else if (name == 'SDirectory') {
      return 'Intertek'
    }
    else if (name == 'UlProductiQ') {
      return 'UL'
    } else {
      return name
    }
  }

  const renderSource = (
    source: string,
    iframeSrc: { link: string; body: string },
    id: number,
    serviceName: string,
    parsedData: any,
    cert: any
  ) => {
    console.log(source, "this is the source");

    return isEditMode ? (
      renderTableInput(source, "source", id)
    ) : source ? (
      // serviceName === "VDE" ||
      serviceName === "SDirectory" ? (
        <p
          onClick={() => urlChecker(parsedData, cert)}
          className="underline text-primary_color hover: cursor-pointer"
        >
          {serviceName ? nameChanger(serviceName) : "Source link"}

        </p>
      ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          className="text-primary_color underline"
          href={source}
          target="_blank"
          rel="noopener"
        >
          {serviceName ? nameChanger(serviceName) : "Source link"}
        </a>
      )
    ) : (
      ""
    );
    // }
  };

  const renderSourceClick = (
    source: string,

    serviceName: string | undefined
  ) => {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className="text-primary_color underline"
        href={source}
        target="_blank"
        rel="noopener"
      >
        {serviceName ? serviceName : "Source link"}
      </a>
    );
    // }
  };

  // if (isAdmin && !rohsTableHeadings.includes("DELETE")) {
  //   rohsTableHeadings.push("DELETE");
  // }

  return (
    <>
      {webLoading ? (
        <Loader />
      ) : (
        <div className={styles.certificatesModalWrap}>
          <h2 className="text-1xl leading-10 font-medium text-primary-500">
            {manufactureName}
          </h2>
          <div className={"flex justify-between"}>
            <h2 className="text-1xl leading-10 font-medium text-primary-500">
              {componentName}
            </h2>
            <div>
              {isAdmin &&
                <Button
                  label={"Add from Existing"}
                  className={"mr-2.5"}
                  onClick={() =>
                    setIsAddExisting(true)
                  }
                />
              }
              {isRoHSAllowed &&
                <Button
                  label={"Add new Material"}
                  className={"mr-2.5"}
                  onClick={() =>
                    setIsAddRoHSModalShown(!isAddRoHSModalShown)
                  }
                />
              }
              {
                <Button
                  label={"Add new certificate "}
                  className={"mr-2.5"}
                  onClick={() =>
                    setIsAddCertificateModalShown(!isAddCertificateModalShown)
                  }
                />
              }
              {isAdmin && (
                <Button
                  label={
                    isEditMode ? "Turn off edit mode" : "Turn on edit mode"
                  }
                  className={"mr-2.5"}
                  onClick={() => handleChangeEditMaterialMode(isEditMode)}
                />
              )}

              <Button
                label={"Delete certificates"}
                disabled={certificatesToDelete.length <= 0}
                onClick={() => handleDeleteCertificates()}
              />

              {isAdmin && certificatesToDeleteRohs && (
                <Button
                  label={"Delete RoHS"}
                  className={"mr-2.5"}
                  loading={isLoading}
                  onClick={() => handleDeleteCertificatesRohs(certificatesToDeleteRohs)}
                />
              )}
            </div>
          </div>
          <div className={"flex justify-between"}>
            <h2 className="text-2xl leading-10 font-medium mb-4 text-primary-500">
              Certification Records
            </h2>
          </div>
          <div className={styles.tableWrapper}>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {tableHeadings.map((heading, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500"
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {showingData.map((cert: any, index: number) => {
                  let parsedData = JSON.parse(cert?.rawData);
                  console.log(cert, "cert");

                  console.log(parsedData, 'parsed');

                  return (
                    <tr
                      className={index % 2 === 0 ? undefined : "bg-gray-50"}
                      key={cert.id}
                    >
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {renderSource(
                            cert?.source && cert?.source.includes("http") ? cert?.source : cert?.localPath,
                            cert?.dataForIframe,
                            cert.id,
                            cert.serviceName,
                            parsedData,
                            cert
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>

                          {renderTableInput(
                            cert?.rawData
                              ?
                              parsedData?.certificateNumber == "" ? parsedData.cert.certificateNumber : parsedData?.certificateNumber
                              : cert.referenceNumber,
                            "referenceNumber",
                            cert.id
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {renderTableInput(
                            cert?.expireDate,
                            "expireDate",
                            cert.id
                          )}
                        </div>
                        {cert?.expireDate && (
                          <Button
                            color={BUTTON_COLORS.normal}
                            label="Send Mail"
                            // loading={isMailLoading}
                            onClick={() => sendDirectMail(cert)}
                          />
                        )}
                      </td>

                      {
                        isAdmin && <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div style={{ maxWidth: 150 }}>
                            {cert.doc ? cert.doc.certainliDocNo : ''}
                          </div>
                        </td>
                      }
                      {isAdmin

                        && <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                          <div>
                            {renderCertificateCheckBox(
                              cert?.confidential ?? false,
                              "confidential",
                              cert.id
                            )}
                          </div>
                        </td>}
                      {isAdmin && <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                        <input
                          className="text-green-600 focus:ring-green-600"
                          type={"checkbox"}
                          checked={certificatesToDelete.indexOf(cert.id) >= 0}
                          onChange={() => handleAddCertificateToDelete(cert.id)}
                        />
                      </td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isRoHSAllowed && <>

            <div className={"flex justify-between"}>
              <h2 className="text-2xl leading-10 font-medium mb-4 text-primary-500">
                Material Declarations
              </h2>

            </div>
            <div className={styles.tableWrapper}>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {rohsTableHeadings.map((heading, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500"
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {rohsFiles.map((file: any, index: number) => {

                    // console.log(file, "file data here");
                    if (!selectedManufacturer) {

                    }

                    if (!file?.path?.startsWith('http') && !file?.path?.startsWith('https')) {
                      let baseLink = process.env.REACT_APP_API_URL;
                      baseLink = baseLink?.replace(/[#\/]+$/, '');

                      // if (!baseLink?.endsWith('/')) {
                      //   baseLink += '/';
                      // }

                      file.path = baseLink + file.path;
                      console.log("file path===>", file.path);
                    }
                    return <tr key={index}
                      className={"bg-gray-50"}>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>

                          {

                            renderSourceClick(file.path == "" ? file.path2 : file.path, file.type)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {<div style={{ maxWidth: 150 }}>{file.dateIssue}</div>}
                        </div>
                      </td>
                      {
                        isAdmin && <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div style={{ maxWidth: 150 }}>
                            {<div style={{ maxWidth: 150 }}>{file.doc ? file.doc.certainliDocNo : ''}</div>}
                          </div>
                        </td>
                      }

                      {/* <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                 <div style={{ maxWidth: 150 }}>{type}</div>
               </td>
              
               <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                 <input
                   className="text-green-600 focus:ring-green-600"
                   type={"checkbox"}
                   checked={isApprove}
                   onChange={async () =>                        
                     approveStatus()
                   }
                 />
               </td>
               <td className="whitespace-nowrap px-3 py-3 text-center align-top text-sm text-gray-500">
                 <input
                   className="text-green-600 focus:ring-green-600"
                   type={"checkbox"}
                   // checked={materialCertificatesID}
                   onChange={() =>
                     handleAddCertificateToDeleteRohs(+materialCertificatesID)
                   }
                 />
               </td> */}
                      <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <div style={{ maxWidth: 150 }}>
                          {<div style={{ maxWidth: 150 }}> {renderRoHSTableInput(
                            selectedManufacturer[index] || file.manufacturer || file.material_id_rohs.manufacturer,
                            "manufacturer",
                            file.id,
                            index
                          )}</div>}
                        </div>
                      </td>
                      <td>

                        {isAdmin && <CreatableSelect
                          styles={selectcustomStyles(150)}
                          placeholder={selectedType[index] || file.type2 || "Select Type"}
                          menuPortalTarget={document.body}
                          isMulti={false}
                          isDisabled={!isEditMode}

                          options={typeOptions.map(
                            (option) => ({
                              value: option,
                              label: option,
                            })
                          )}
                          value={selectedType[index]}
                          onChange={(item: any) => {

                            selectedType[index] = item
                            setSelectedType([...selectedType]);
                            if (!typeOptions.includes(item["value"])) {
                              typeOptions[2] = item["value"];
                              setTypeOptions([...typeOptions]);
                            }

                          }
                          }


                          onInputChange={(item: any) => {
                            // const item = {
                            //   label: val,
                            //   value: val
                            // };
                            console.log("custom input ==   ====>", item)

                            // selectedType[index] = item;

                            // setSelectedType([...selectedType]);


                          }}
                        />}

                      </td>
                      <td>


                        {isAdmin && <TextareaWithDropdown
                          isEditMode={!isEditMode}
                          index={index}
                          file={file}
                          setdbSeries={setdbSeries}
                          dbSeries={dbSeries}
                          selectedSeries={selectedSeries}
                          setSelectedSeries={setSelectedSeries}
                        />}

                        {/* <CreatableSelect

                          styles={selectcustomStyles(150)}
                          placeholder={selectedSeries[index] || file.series || "Select Series"}
                          menuPortalTarget={document.body}
                          isMulti={false}

                          isDisabled={!isEditMode}

                          options={ seriesOptions.map(
                            (option) => ({
                              value: option,
                              label: option,
                            })
                          )}
                          value={selectedSeries[index]}

                          

                          onChange={(item: any) => {
                            // handleInputChange(item.target.value, index)
                            // console.log("custom input ===>", item)
                            selectedSeries[index] = item;
                            setSelectedSeries([...selectedSeries])
                            if (!seriesOptions.includes(item["value"])) {
                              seriesOptions[2] = item["value"];
                              setSeriesOptions([...seriesOptions])
                              // setdbSeries([...seriesOptions])
                            }
                          }
                          }

                          onInputChange={(item: any) => {
                            console.log("custom input ==   ====>", item)
                          }}
                        /> */}


                      </td>
                      <td>


                        {isAdmin && <CreatableSelect


                          styles={selectcustomStyles(150)}
                          placeholder={selectedMPN[index] || file.mpn || "Select MPN"}
                          menuPortalTarget={document.body}
                          isMulti={false}

                          isDisabled={!isEditMode}
                          options={MPNOptions.map(
                            (option) => ({
                              value: option,
                              label: option,
                            })
                          )}
                          value={selectedMPN[index]}
                          onChange={(item: any) => {
                            console.log("custom input ==   ====>", item)

                            selectedMPN[index] = item;
                            setSelectedMPN([...selectedMPN]);
                            if (!MPNOptions.includes(item["value"])) {
                              MPNOptions[2] = item["value"];
                              setMPNOptions([...MPNOptions])
                            }

                          }
                          }

                          onInputChange={(item: any) => {
                            console.log("custom input ==   ====>", item)

                          }}
                        />}

                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </>
          }
          {/* <div className="flex items-center h-10 bg-gray-50 text-sm text-gray-500 px-5">
            <p className="flex flex-1">REQUIRED CERTIFICATES</p>
            <p className="flex flex-1">
              {_.join(
                requiredCertificates?.map(
                  (item: { title: string; userId: number }) => item.title
                ),
                ", "
              )}
            </p>
          </div> */}
        </div>
      )}
      <CustomModal
        setModalShown={() => setIdCertificateToUpload(false)}
        isShown={!!idCertificateToUpload}
      >
        <div className={styles.addModalWrap}>
          <h2 className="text-4xl leading-10 font-medium mb-4 text-primary-500">
            Upload new certificate
          </h2>
          <div className={"mt-6"}>
            <FileUploader
              handleChange={setFile}
              onSizeError={(err: string) => toast.error(err)}
              onTypeError={(err: string) => toast.error(err)}
              name="file"
              classes={"DragDropFiles"}
              types={["pdf"]}
              maxSize={10}
              children={
                <div className="DragDropFiles__body">
                  <Upload className="DragDropFiles__icon" />
                  <div className="DragDropFiles__body__title">
                    Upload a file <span>or drag and drop</span>
                  </div>
                  <div className="DragDropFiles__body__subtitle">
                    PDF up to 10MB
                  </div>
                </div>
              }
            />
            { }

            {file ? (
              <div className="AddProduct__fileView">
                <div className="AddProduct__fileView__left">
                  <Document className="AddProduct__fileView__icon" />
                  <div className="AddProduct__fileView__text">{file?.name}</div>
                </div>
                <div
                  className="AddProduct__fileView__right"
                  onClick={() => setFile(null)}
                >
                  Remove
                </div>
              </div>
            ) : null}
          </div>
          <div className={"mt-6 flex justify-end"}>
            <Button
              label="Upload"
              disabled={!file || isLoading}
              onClick={() => onUploadHandler()}
            />
          </div>
        </div>
      </CustomModal>
      <CustomModal
        setModalShown={() => setIsAddRoHSModalShown(false)}
        isShown={isAddRoHSModalShown}
      >
        <AddRoHS
          materialId={materialCertificatesID}
          mutate={mutate}
          closeModal={() => {
            setIsAddRoHSModalShown(false);

            closeModal()
          }}
        />
      </CustomModal>

      <CustomModal
        setModalShown={() => setIsAddExisting(false)}
        isShown={isAddExisting}
      >
        <AddExisting
          getRohsFiles={getRohsFiles}
          materialId={materialCertificatesID}
  productId={productId}

          mutate={mutate}
          closeModal={() => {
            setIsAddExisting(false);

            // closeModal()
          }}
        />
      </CustomModal>
      <CustomModal
        setModalShown={() => setIsAddCertificateModalShown(false)}
        isShown={isAddCertificateModalShown}
      >
        <AddCertificate
          materialId={materialCertificatesID}
          mutate={mutate}
          closeModal={() => setIsAddCertificateModalShown(false)}
        />
      </CustomModal>
      {iframeModalData && (
        <CustomModal
          setModalShown={() => setIframeModalData(false)}
          isShown={!!iframeModalData}
        >
          <iframe
            className={styles.iframeModal}
            src={`${iframeModalData?.link}/?${iframeModalData.body}`}
          />
        </CustomModal>
      )}
    </>
  );
};

export default MaterialCertificatesList;
