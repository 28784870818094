export const selectCustomStyles = {
    input: (provided: any, state: any) => ({
        ...provided,
        border: "none !important",
        outline: "none !important",
        input: "border: none; outline:none",
        "& input:focus": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important",
        },
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
      
};export const selectcustomStyles =(width:number)=>{
    return {
        input: (provided: any, state: any) => ({
            ...provided,
            border: "none !important",
            outline: "none !important",
            input: "border: none; outline:none",
            "& input:focus": {
                outline: "none !important",
                border: "none !important",
                boxShadow: "none !important",
                
            },
        }),
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
          }),

          container: (provided: any) => ({
            ...provided,
            width: width, // Example: Set container width
          }),
          placeholder: (provided: any) => ({
            ...provided,
            width: 'calc(100% - 20px)', // Adjust the placeholder text width
            whiteSpace: 'nowrap', // Prevents text from wrapping
            overflow: 'hidden', // Ensures overflow text is hidden
            textOverflow: 'ellipsis', // Adds ellipsis for overflow text
          }),
        
          control: (provided: any) => ({
            ...provided,
            height: 40, // Set the height of the control (input field and dropdown indicator)

          }),
        
        
    };
}


