// // import * as React from 'react';
// // import Box from '@mui/material/Box';
// // import TextField from '@mui/material/TextField';

// // export default function MultilineTextFields() {
// //   const [open, setOpen] = React.useState(false)
// //   return (


// //     <div className='relative'>

// //     <div className='flex items-center'>

// //     <textarea className='h-9 mt-1 rounded-sm text-xs'/>
// //     <button onClick={()=> setOpen(!open)}>d</button>
// //     </div>
// //     {!! open && <div className='bg-red-300 absolute left-0 top-9 h-20 z-50 w-20'>hello</div> 
// //     }
// //     {/* <div className={` ${open && ""}`}></div> */}
// //     </div>








// //   );
// // }

// // import React, { useState } from 'react';

// // const TextareaWithDropdown = () => {
// //   const [open, setOpen] = useState(false);

// //   return (
// //     <div className='relative'>
// //       <div className='flex items-center'>
// //         <textarea className='h-9 mt-1 rounded-sm text-xs' placeholder="Type here..." />
// //         <button 
// //           onClick={() => setOpen(!open)}
// //           className='ml-2 px-3 py-1 bg-blue-500 text-white rounded'
// //         >
// //           Toggle
// //         </button>
// //       </div>

// //       {open && (
// //         <div className='bg-red-300 absolute left-0 top-9 h-20 w-40 z-50 rounded shadow-lg'>
// //           <div className='p-2'>
// //             <ul>
// //               <li className='p-2 hover:bg-red-400'>Option 1</li>
// //               <li className='p-2 hover:bg-red-400'>Option 2</li>
// //               <li className='p-2 hover:bg-red-400'>Option 3</li>
// //             </ul>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default TextareaWithDropdown;

// import React, { useEffect, useState } from 'react';
// import { selectcustomStyles } from '../../constants/selectStyles';

// function TextareaWithDropdown({ isEdited, setSelectedSeries, selectedSeries, seriesOptions, setSeriesOptions, index, series }: any) {
//   const [selectedOption, setSelectedOption] = useState('');
//   const [textareaValue, setTextareaValue] = useState('');
//   const [dropdownVisible, setDropdownVisible] = useState(false);
// useEffect(()=>{
//   if(series){
//     setTextareaValue(series);
//   }

// },[series])
//   const handleDropdownChange = (event: any) => {
//     const selectedValue = event.target.value;

//     setTextareaValue(`${selectedValue}`);
//     selectedSeries[index] = selectedValue;
//     setSelectedSeries([...selectedSeries])
//     if (!seriesOptions.includes(selectedValue)) {
//       seriesOptions[2] = selectedValue;
//       setSeriesOptions([...seriesOptions])
//     }
//     toggleDropdown()
//   };

//   const handleTextareaChange = (event: any) => {
//     const selectedValue = event.target.value;
//     setTextareaValue(event.target.value);
//     selectedSeries[index] = selectedValue;
//     setSelectedSeries([...selectedSeries])
//     if (!seriesOptions.includes(selectedValue)) {
//       seriesOptions[2] = selectedValue;
//       setSeriesOptions([...seriesOptions])
//     }
//   };
//   const toggleDropdown = () => {


//     setDropdownVisible(!dropdownVisible);
//   };


//   return (
//     <div>

//       <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//         {!dropdownVisible &&
//           <textarea

//             placeholder={selectedSeries[index] || series || "Select Series"}
//             disabled={isEdited}
//             value={textareaValue}
//             onChange={handleTextareaChange}
//             className={`h-10 w-29 p-2 border border-gray-200 rounded-md ${isEdited ? "bg-gray-100" : "bg-white"}`}


//           />
//         }

//         {!dropdownVisible && <button
//           disabled={isEdited}

//           onClick={() => {
//             toggleDropdown();
//             const selectedValue = "All";

//             setTextareaValue(`${selectedValue}`);
//             selectedSeries[index] = selectedValue;
//             setSelectedSeries([...selectedSeries])
//             if (!seriesOptions.includes(selectedValue)) {
//               seriesOptions[2] = selectedValue;
//               setSeriesOptions([...seriesOptions])
//             }
//           }}
//           className="focus:outline-none" // Removes default outline on focus
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             // viewBox="0 0 24 24"
//             // strokeWidth={2}
//             stroke="currentColor"
//             className={`w-6 h-6 ${isEdited ? "bg-gray-100" : "bg-white"}`}
//           >
//             <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
//           </svg>

//         </button>}
//         {dropdownVisible && <select
//           disabled={isEdited}

//           className={`h-10 w-56 p-2 border bg-white border-gray-300 rounded-md  ${isEdited ? "bg-gray-100" : "bg-white"}`}
//           value={selectedOption}

//           onChange={handleDropdownChange}>

//           <option value="All">All</option>
//           <option value="All with exception">All with exception</option>

//         </select>}



//       </div>

//       {/* <div className="textarea-container" style={{ marginTop: '20px' }}>
//         <textarea
//           value={textareaValue}
//           onChange={handleTextareaChange}
//           // rows="5"
//           // cols="40"
//           placeholder="Your content will appear here..."
//         />
//       </div> */}
//     </div>

//   );
// }

// export default TextareaWithDropdown;

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';

// export default function MultilineTextFields() {
//   const [open, setOpen] = React.useState(false)
//   return (


//     <div className='relative'>

//     <div className='flex items-center'>

//     <textarea className='h-9 mt-1 rounded-sm text-xs'/>
//     <button onClick={()=> setOpen(!open)}>d</button>
//     </div>
//     {!! open && <div className='bg-red-300 absolute left-0 top-9 h-20 z-50 w-20'>hello</div> 
//     }
//     {/* <div className={` ${open && ""}`}></div> */}
//     </div>








//   );
// }

// import React, { useState } from 'react';

// const TextareaWithDropdown = () => {
//   const [open, setOpen] = useState(false);

//   return (
//     <div className='relative'>
//       <div className='flex items-center'>
//         <textarea className='h-9 mt-1 rounded-sm text-xs' placeholder="Type here..." />
//         <button 
//           onClick={() => setOpen(!open)}
//           className='ml-2 px-3 py-1 bg-blue-500 text-white rounded'
//         >
//           Toggle
//         </button>
//       </div>

//       {open && (
//         <div className='bg-red-300 absolute left-0 top-9 h-20 w-40 z-50 rounded shadow-lg'>
//           <div className='p-2'>
//             <ul>
//               <li className='p-2 hover:bg-red-400'>Option 1</li>
//               <li className='p-2 hover:bg-red-400'>Option 2</li>
//               <li className='p-2 hover:bg-red-400'>Option 3</li>
//             </ul>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TextareaWithDropdown;
// interface Option {
//   readonly label: string;
//   readonly value: string;
// }

import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { selectcustomStyles } from '../../constants/selectStyles';
import CreatableSelect from "react-select/creatable";
import { AnyARecord } from 'dns';
import { CreateFunction } from '@testing-library/react';
// import { handleInputChange } from 'react-select/dist/declarations/src/utils';


interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});


function TextareaWithDropdown({ isEditMode, index, file,
  setdbSeries, dbSeries, selectedSeries, setSelectedSeries

}: any) {

  // let [selectedSeries, setSelectedSeries] = useState<Array<any>>([]);
  // let [dbSeries, setdbSeries] = useState<any>([]);
  // const [inputValue, setInputValue] = React.useState('');

  let [value, setValue] = React.useState<Option[]>([]

  );

  const [seriesOptions, setSeriesOptions] = useState<Array<string>>([
    "All",

    "All with exclusions"

  ]);
  useEffect(() => {
    if (file?.rohs_series.length > 0) {
      let list: string[] = [];
      file?.rohs_series.map((item: any) => {
        if (!seriesOptions.includes(item.series)) {

          list.push(item.series)
        }
      });

      setSeriesOptions([...seriesOptions, ...list]);
    }
    // if (file.series) {
    //   let parseDAta=file.series;
    //   try{

    //      parseDAta = JSON.parse(file.series);
    //   }catch(e){

    //   }
    //   //  if(parseDAta.)
    //   if (typeof parseDAta === "string") {
    //     setValue((prev) => [...prev, createOption(parseDAta)]);
    //     setdbSeries((prev:any) => [...prev, {
    //       value:parseDAta,
    //       id:file.id
    //     }]);
    //   } else {

    //     let tempList: Array<Option> = []
    //     let tempList2: Array<any> = dbSeries.filter((item:any)=>item.id!=file.id);

    //     for (let i = 0; i < parseDAta.length; i++) {
    //       tempList.push(createOption(parseDAta[i]))
    //       tempList2.push({
    //       value:parseDAta[i],
    //       id:file.id
    //       });


    //     }
    //     if (tempList.length > 0) {
    //       setValue((prev) => [...prev, ...tempList]);
    //       setdbSeries( [...tempList2]);
    //     }
    //   }
    // }
  }, [file.rohs_series])

  function storeInputValue(inputValues: string) {
    console.log("input storage ==>  ", inputValues)
    selectedSeries[index] = inputValues;
    setSelectedSeries([...selectedSeries])
    const exists = value.some(option => option.value.toLowerCase() == inputValues.toLowerCase());
    // if(!exists){
    setValue((prev) => [...prev, createOption(inputValues)]);
    setSeriesOptions((prev) => [...prev, inputValues])

    // }
    // if (!seriesOptions.includes(inputValues)) {
    //   seriesOptions.push(inputValues)
    //   setSeriesOptions((prev)=>[...prev,inputValue])
    //   const newValue = {
    //     value: inputValues,
    //     id: file.id
    //   };

    // }
  }


  return (
    <CreatableSelect
    // className='min-h10 max-h10'
      tabSelectsValue={true}
      // styles={selectcustomStyles(150)}
      // placeholder={selectedSeries[index] || file.series || "Select Series"}
      placeholder={"Select Series"}
      menuPortalTarget={document.body}
      isMulti={true}
      // menuShouldScrollIntoView={true}

      isDisabled={isEditMode}
      // onKeyDown={handleKeyDown}

      isClearable
      options={seriesOptions.map(
        (option) => ({
          value: option,
          label: option,
        })
      )}
      // value={selectedSeries}
      value={value}

      // menuIsOpen={true}
      onCreateOption={(inputValue: string) => {


        const exists = value.some(option => option.value == inputValue);
        if (!seriesOptions.includes(inputValue)) {

          setSeriesOptions((prev) => [...prev, inputValue])
          setValue((prev) => [...prev, createOption(inputValue)]);

          setdbSeries((prev: any) => [...prev, {
            value: inputValue,
            id: file.id
          }])

        }


      }}
      onChange={(newValue: any) => {
        console.log("daa ------>", newValue)
        let list = dbSeries.filter((item: any) => item.id != file.id);
        // for (let i = 0; i < list.length; i++) {
        //   let item = list[i];
        //   if (item?.id == file.id) {
        //     list.splice(i, 1);

        //   }
        // }


        if (newValue?.length > 0) {

          newValue.map((item: any) => {
            list.push(
              {
                value: item.value,
                id: file.id
              }
            )

          })

        }
        setdbSeries([...list]);
        console.log("daa ------>", newValue.length, dbSeries.length, list.length)
        setValue(newValue)

      }}

    // onChange={(item: any) => {

    //   console.log("custom input ===>",typeof item)
    //   selectedSeries[index] = item;
    //   setSelectedSeries([...selectedSeries])
    //   if (!seriesOptions.includes(item["value"])) {
    //     seriesOptions[2] = item["value"]; 
    //     setSeriesOptions([...seriesOptions])
    //     dbSeries[index] = {
    //       value: item,
    //       id: file.id
    //     };
    //     setdbSeries([...dbSeries])
    //   }
    // }
    // }
    // onInputChange={(newValue) => setInputValue(newValue)}

    // onInputChange={(item: any) => {
    //   // console.log("custom input ==   ====>", item)
    // }}
    />

  );
}

export default TextareaWithDropdown;
