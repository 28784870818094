import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css";
import "./Products.scss";
import useUser from "../../api/SWR/useUser";
import { fetcher } from "../../api/SWR/fetchers";
import ProductsTable from "../../components/products-table/ProductsTable";
import { ProductType } from "../../types";
// import ModalContentWrapper from "../../components/modal-content-wrapper/ModalContentWrapper";
import CustomModel from "../../components/custom-modal/CustomModel";
import TermsAndConditions from '../../components/terms-and-conditions/TermsAndConditions'
export type ProductsPropsTypes = {
  products: ProductType[];
  loading: boolean;
  mutate: any;
};

const Products = ({ products, loading, mutate }: ProductsPropsTypes) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const data = useUser();

  useEffect(() => {
    if (!data.data.isTermsAccept) {
      setModalIsOpen(true)
    }
  }, [])
  const cancelHandler = () => {
    setModalIsOpen(false)
  }
  const acceptHandler = async () => {
    try {
      const formData = new FormData()
      formData.append('id', data?.data?.id)
      formData.append('isTermsAccept', JSON.stringify(true));

      await fetcher({
        key: 'user/update',
        data: formData
      })
      data.mutate();
      setModalIsOpen(false)
    } catch (error) {
      console.log(error)
      setModalIsOpen(false)
    }
  }

  if (loading) return <Loader />;
  return (
    <>
      <CustomModel
        isShown={modalIsOpen}
      // ariaHideApp={false}
      // shouldCloseOnOverlayClick
      // setModalShown={() => setModalIsOpen(false)}
      >
        <TermsAndConditions handleCancel={cancelHandler} handleAccept={acceptHandler} />
      </CustomModel>
      {products.length <= 0 ? (
        <div className="">No products found</div>
      ) : (

        <ProductsTable products={products} mutate={mutate} />
      )}
    </>
  );
};

export default Products;
