import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetcher } from "../../api/SWR/fetchers";
import { toast } from "react-toastify";
import { Input } from "../input/Input";
import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as Upload } from "../../static/assets/svg/upload.svg";
import { ReactComponent as Document } from "../../static/assets/svg/document.svg";
import { Button } from "../button/Button";
import _ from "lodash";
import useUser from "../../api/SWR/useUser";

const validationSchema = Yup.object().shape({
  id: Yup.string().required("Required")
});

const AddExisting = ({
  getRohsFiles,
  materialId,
  productId,
  mutate,
  closeModal,
}: {
  getRohsFiles: any;
  materialId: number | boolean;
  productId:number|boolean;

  mutate: any;
  closeModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);

  // const user = useUser();
  // useEffect(()=>{
  //   console.log("user id ===========>",user);
  // },[user])
  const formik = useFormik({
    validateOnChange: true,
    validationSchema: validationSchema,
    initialValues: {
      id: "",
    },
    onSubmit: async (values, { setErrors }) => {
      setIsLoading(true);
      console.log(materialId);

      if (materialId) {
        let payload: any = values;
        payload.materialId = materialId;
        payload.productId=productId;
        try {
          let val = await fetcher({
            key: "documents/find",
            data: payload,
          });
          // if (val.length > 0) {
          if(val){
            await getRohsFiles(materialId)
            toast.success("Document Added Successfully!");
            await mutate();
            closeModal();
          } else {
            toast.error("No Document Found!");
          }
        } catch (e) {
          console.log(e.message)
          setIsLoading(false);
          // toast.error(e.message);
          toast.error(_.get(e, 'response.data.message', "Error"));
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <div className={styles.wrapper}>
      <h2 className="text-4xl leading-10 font-medium mb-2.5 text-primary-500 mb-5">
        Add from existing
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <div className={"w-full mb-3"}>
          <Input
            type="text"
            label="C-Doc Number"
            id="id"
            name="id"
            onChange={formik?.handleChange}
            value={formik?.values?.id}
            errorText={formik?.errors?.id}
            placeholder="Number"
          />
        </div>

        <div className={"flex justify-end"}>
          <Button loading={isLoading} label={"Add"} type={"submit"} />
        </div>
      </form>
    </div>
  );
};

export default AddExisting;
