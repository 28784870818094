import axios from "axios";
import { baseUrl } from "../../api/constants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditRoHSCertificateField from "../ui/edit-certificate-field/EditRoHSCertificateField";
import CreatableSelect from "react-select/creatable";
import { DOCUMENTMATERIALS, SEARCH_COMPONENTS } from "../../constants/enums";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import styles from "../materials-table/styles.module.scss";
import { number } from "yup";


const DcoumentsMaterials = () => {
    const itemsPerPage = 10;
    const [loading, setLoading] = useState<boolean>(false)
    let [rohData, setRohs] = useState([])
    let [temprohData, settempRohs] = useState([])

    const [isEditMode, setIsEditMode] = useState(false);

    let [selectedManufacturer, setselectedManufacturer] = useState<any>([]);


    const [searchDD, setSearchDDValue] = useState<any>(DOCUMENTMATERIALS.type);
    const [searchValue, setSearchValue] = useState<string>('')
    const [callbackState, setCallbackState] = useState<object>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCountValue, setPageCountValue] = useState<any>(0);
    let [showingData, setShowingData] = useState([]);

    let pageCount = Math.ceil(pageCountValue / itemsPerPage);
    const tableHeadings = ["SOURCE NAME", "MANUFACTURER", "TYPE", "SERIES", "MPN"];

    const getCertificates = async (page: number = 4, limit: number = 40) => {
        setLoading(true);
        try {
            const response = await axios.get(baseUrl + "/rohs/get-rohs", {
                params: {
                    "page": page,
                    "limit": limit
                }

            });
            if (response.status == 200) {
                const data: [] = response.data;

                let length = data.length + rohData.length;
                setRohs([...rohData, ...data]);
                setPageCountValue(length);

            }
        } catch (e) {
            toast.error(e?.response?.data);
        }

        setLoading(false);


    }
    useEffect(() => {

        let updatedData = rohData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
        console.log("updated Data ==>>>", updatedData);

        setShowingData(updatedData);
        console.log(updatedData, "showingData calling");

    }, [currentPage, rohData]);

    useEffect(() => {
        getCertificates();
    }, [])

    function ManufactureCallback(value: any, index: number) {
        if (value) {
            selectedManufacturer[index] = value;
            setselectedManufacturer(selectedManufacturer);
        }
    }
    const renderRoHSTableInput = (
        value: string | undefined,
        type: string,
        id: number,
        index: number,
    ) => {
        if (isEditMode) {
            return <EditRoHSCertificateField currentValue={value} type={type} id={id} callback={ManufactureCallback} index={index} />;
        } else return value;
    };

    const SelectComponent = ({ value, onChange }: any) => {
        return (
            <td className="whitespace-nowrap align-top text-sm text-gray-500">{
                <select
                    className="component-select rounded py-1.5 border border-gray-200 w-24 mr-1.5"
                    // className={styles.componentSelect}
                    onChange={onChange}
                    value={value}
                    name="componentStatus"
                    id="component-status"
                >

                    <option value={DOCUMENTMATERIALS.type}>TYPE</option>
                    <option value={DOCUMENTMATERIALS.sourceName}>SOURCE NMAE</option>
                    <option value={DOCUMENTMATERIALS.mpn}>MPN</option>
                    <option value={DOCUMENTMATERIALS.manufacturer}>MANUFACTURER</option>
                    <option value={DOCUMENTMATERIALS.series}>SERIES</option>

                </select>
            }
            </td>
        );
    };
    const handleSelectChange = (e: any) => {
        console.log("======> ", e.target.value)
        setSearchDDValue(e.target.value);


    };
    const searchHandler = (e: any) => {
        setSearchValue(e.target.value);
        if (!e.target.value) {
            console.log("-----====////////>", e.target.value)

            setCallbackState({})
        }

    }
    const submittSearch = () => {
        if (searchValue.length == 0) {
            // alert("Write something to search")
            toast.error("Write Something to Search");
            return;
        }
        const regex = /[.,\/#%*+\s-]/g;
        const query = searchValue.replace(regex, "");
        const tempMap = {
            "type": searchDD,
            "query": query.trim().toLowerCase()
        };
        console.log("serach value ==>", showingData);
        if (temprohData.length > 0) {
            showingData = temprohData;
        } else {
            settempRohs(showingData);
        }
        // setCallbackState(tempMap);
        const filteredItems = showingData.filter((item: any) => {

            let manufacturer
            if (tempMap['type'] == DOCUMENTMATERIALS.manufacturer) {

                item[tempMap['type']] = item.material_id_rohs[tempMap['type']];
            }
            console.log("item tupe w2333", item.type2);


            item.type2 = item.type2 ?? item.material_id_rohs.type;



            manufacturer = item[tempMap['type']]; // Convert to lowercase for case-insensitive comparison

            if (manufacturer) {
                let queryLower: string =
                    tempMap["query"];
                if (tempMap['type'] == DOCUMENTMATERIALS.series) {

                    return manufacturer.includes(queryLower);
                }
                manufacturer = manufacturer.toLowerCase();

                const regex = /[.,\/#%*+\s-]/g;
                const filterString = manufacturer.replace(regex, "");


                console.log("check ==----->", filterString);

                return filterString.includes(queryLower);
            }
            return;

        });
        console.log("callback ===>", filteredItems);
        if (filteredItems.length > 0) {
            toast.success("Success!");
        } else {
            toast.error("No Component Found");
        }
        setShowingData(filteredItems);

    }

    const showPagesCount = (pageCount: number, currentPage: number) => {
        let array: number[] = Array.from(
            new Set([
                1,
                2,
                3,
                currentPage < 2 ? 1 : currentPage - 1,
                currentPage,
                currentPage >= pageCount ? pageCount : currentPage + 1,
                pageCount - 2,
                pageCount - 1,
                pageCount,
            ])
        )
            .sort((a, b) => a - b)
            .filter((el) => el > 0 && el <= pageCount);
        for (let i = 1; i < array.length; i++) {
            if (array[i] - 1 !== array[i - 1]) {
                array = [...array.slice(0, i), 0, ...array.slice(i)];
                i++;
            }
        }

        return array;
    };
    const sortingRecord = () => {

        let updatedData = rohData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

        setRohs(updatedData);
    }
    return (
        <>
            {/* for serach */}
            <div className="flex">
                {SelectComponent({ value: searchDD, onChange: handleSelectChange })}
                <Input
                    style={{ width: "250px" }}
                    value={searchValue}

                    onChange={searchHandler}
                    placeholder="Search"
                    inputValue={searchValue}

                    handleClearInput={() => {
                        setCallbackState({});
                        setSearchValue("");
                        if (temprohData.length > 0) {
                            setShowingData(temprohData)
                            settempRohs([]);

                        }
                    }
                    }
                ></Input>
                <Button
                    label=
                    "Search"

                    className={"mr-2.5, ml-1.5"}
                    onClick={submittSearch}
                />
            </div>



            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {tableHeadings.map((heading) => (
                                            <th
                                                key={heading}
                                                scope="col"
                                                className="App__container px-3 py-3 text-left text-xs leading-4 font-black tracking-wider uppercase text-gray-900"
                                            >
                                                {heading}
                                            </th>
                                        ))}
                                        <th scope="col" className="relative py-3.5 ">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {showingData.map((file: any, index: number) => {


                                        if (!file?.path?.startsWith('http') && !file?.path?.startsWith('https')) {
                                            let baseLink = process.env.REACT_APP_API_URL;
                                            baseLink = baseLink?.replace(/[#\/]+$/, '');



                                            file.path = baseLink + file.path;
                                            console.log("file path===>", file.path);
                                        }
                                        return <tr key={index}
                                            className={"bg-gray-50"}>
                                            <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                <div style={{ maxWidth: 150 }}>

                                                    {

                                                        file.type
                                                    }
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                <div style={{ maxWidth: 150 }}>
                                                    {/* {<div style={{ maxWidth: 150 }}>{file.dateIssue}</div>} */}
                                                    {<div style={{ maxWidth: 150 }}> {renderRoHSTableInput(
                                                        selectedManufacturer[index] || file.manufacturer || file.material_id_rohs.manufacturer,
                                                        "manufacturer",
                                                        file.id,
                                                        index
                                                    )}</div>}
                                                </div>
                                            </td>
                                            {
                                                <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    <div style={{ maxWidth: 150 }}>
                                                        {<div style={{ maxWidth: 150 }}>
                                                            {file.type2 ?? file.material_id_rohs.type}
                                                        </div>}
                                                    </div>
                                                </td>
                                            }


                                            <td className="whitespace-nowrap px-3 py-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                <div style={{ maxWidth: 150 }}>
                                                    {<div style={{ maxWidth: 150 }}> {
                                                        file.rohs_series.includes("All") ? "All" : file.rohs_series.includes("All with exclusions") ? "All with exclusions" : file.rohs_series[0]}</div>}
                                                </div>
                                            </td>
                                            <td>

                                                <div>
                                                    {

                                                        file.mpn
                                                    }
                                                </div>

                                            </td>
                                            <td>


                                                {/* {isAdmin && <TextareaWithDropdown
                                                    isEditMode={!isEditMode}
                                                    index={index}
                                                    file={file}
                                                    setdbSeries={setdbSeries}
                                                    dbSeries={dbSeries}
                                                    selectedSeries={selectedSeries}
                                                    setSelectedSeries={setSelectedSeries}
                                                />} */}




                                            </td>
                                            <td>


                                                {/* {isAdmin && <CreatableSelect


                                                    styles={selectcustomStyles(150)}
                                                    placeholder={selectedMPN[index] || file.mpn || "Select MPN"}
                                                    menuPortalTarget={document.body}
                                                    isMulti={false}

                                                    isDisabled={!isEditMode}
                                                    options={MPNOptions.map(
                                                        (option) => ({
                                                            value: option,
                                                            label: option,
                                                        })
                                                    )}
                                                    value={selectedMPN[index]}
                                                    onChange={(item: any) => {
                                                        console.log("custom input ==   ====>", item)

                                                        selectedMPN[index] = item;
                                                        setSelectedMPN([...selectedMPN]);
                                                        if (!MPNOptions.includes(item["value"])) {
                                                            MPNOptions[2] = item["value"];
                                                            setMPNOptions([...MPNOptions])
                                                        }

                                                    }
                                                    }

                                                    onInputChange={(item: any) => {
                                                        console.log("custom input ==   ====>", item)

                                                    }}
                                                />} */}

                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <nav
                            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                        >
                            <button
                                onClick={() =>
                                    setCurrentPage((currentPage) => currentPage - 1)
                                }
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>

                            {showPagesCount(pageCount, currentPage).map(
                                (elem, ind) => {
                                    if (elem === 0) {
                                        return (
                                            <div
                                                key={elem}
                                                aria-current="page"
                                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                                            >
                                                ...
                                            </div>
                                        );
                                    }
                                    return (
                                        <button
                                            key={elem}
                                            onClick={() => setCurrentPage(elem)}
                                            aria-current="page"
                                            className={
                                                elem === currentPage
                                                    ? styles.activeBtn
                                                    : styles.defaultBtn
                                            }
                                        >
                                            {elem}
                                        </button>
                                    );
                                }
                            )}

                            <button
                                // disabled={pageCount === currentPage}
                                onClick={() =>
                                    setCurrentPage((currentPage) => {
                                        if (pageCount === currentPage) {
                                            getCertificates(currentPage + 3, 30)
                                        }
                                        console.log("page count   ", currentPage + 1);
                                        return currentPage + 1
                                    })
                                }
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DcoumentsMaterials;