import { useEffect, useState } from "react";
import DocumentsView from "../../components/documents/documentsCertificates";
import DocumentsCertificates from "../../components/documents/documentsCertificates";
import DcoumentsMaterials from "../../components/documents/documentesMaterials";
import { Button, BUTTON_COLORS } from "../../components/button/Button";

const DcoumentsContainer = () => {
    let [showCertificates, setCertificates] = useState<boolean>(true);
    const handleCertificates = () => {
        setCertificates(showCertificates = !showCertificates);
    }

    return (
        <>
            <div className="ProductsContainer__header">
                <div className="ProductsContainer__title">Documents</div>
                <div className="ProductsContainer__controls flex flex-col space-y-0">
                    {/* <div className={`${!showCertificates ? "bg-white" : "bg-primary_color"} shadow-lg px-6`} onClick={handleCertificates}>
                        <p className="text-center1" style={{
                            color: showCertificates ? "white" : "black"

                        }}>Certificates</p>
                    </div> */}
                    <Button
                        className={`Certificates px-4 py-2 rounded-lg border ${showCertificates ? 'bg-primary text-white border-primary' : 'bg-white text-black border-gray-200'}`}
                        label="Certificates"
                        onClick={() => {
                            setCertificates(true);
                        }}

                    />
                    <Button
                        className={`Materials px-4 py-2 rounded-lg border ${!showCertificates ? 'bg-primary text-white border-primary' : 'bg-white text-black border-gray-200'}`}
                        label="Material Declarations"
                        onClick={() => {
                            setCertificates(false);

                        }}

                    />
                    {/* <div className={`${showCertificates ? "bg-white" : "bg-primary_color"} shadow-lg px-6`} onClick={handleCertificates}>
                        <p className="text-center2" style={{
                            color: !showCertificates ? "white" : "black"
                        }}>MaterialDeclarations</p>
                    </div> */}
                </div>
                <div></div>
            </div>

            <div>
                {showCertificates ? <DocumentsCertificates /> : <DcoumentsMaterials />}
            </div>
        </>
    );
}

export default DcoumentsContainer;
