import React, { useEffect, useState } from "react";
import Products from "./Products";
import {
  fetcher,
  productComponentNotification,
  scrappingFetcher,
} from "../../api/SWR/fetchers";
import { Button, BUTTON_COLORS } from "../../components/button/Button";
import { ReactComponent as plus } from "../../static/assets/svg/plus.svg";
import { ReactComponent as refresh } from "../../static/assets/svg/refresh.svg";
import { ReactComponent as filter } from "../../static/assets/svg/filter.svg";
import "./ProductsContainer.scss";
import Modal from "react-modal";
import AddProduct from "../../components/add-product/AddProduct";
import ModalContentWrapper from "../../components/modal-content-wrapper/ModalContentWrapper";
import { useFormik } from "formik";
import { materialAPI, productApi } from "../../api/apiRequests";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { parsedFileDataType } from "../../components/add-product/steps/AddProduct2Step";
import _ from "lodash";
import useProducts from "../../api/SWR/useProducts";
import Scrapper from "../../components/scrapper/Scrapper";
import ScrapperList from "../../components/scrapper/ScrapperList";
import { scrappingUrl } from "../../api/constants";
import useUser from "../../api/SWR/useUser";
import CustomModal from "../../components/custom-modal/CustomModal";
import { ProductType } from "../../types";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.array().required("Type is required"),
  standards: Yup.array().required("Standard is required"),
  markets: Yup.array().required("Markets is required"),
  description: Yup.string(),
  developmentStatus: Yup.object().required("Status is required"),
  manufacturer: Yup.string().required("Manufacturer field is required"),
  customer: Yup.string().required("Name is required"),
});

const ProductsContainer = () => {
  const [products, setProducts] = useState<any>([]);
  const [filterProduct, setFilterProduct] = useState({
    open:false,
    selected:'',
    products:[]
  })
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [scrapperModalIsOpen, setScrapperModalIsOpen] =
    useState<boolean>(false);
  const [scrapperListModalIsOpen, setScrapperListModalIsOpen] =
    useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [cancelText, setCancelText] = useState<string>("Cancel");
  const [nextText, setNextText] = useState<string>("Next");
  const [file, setFile] = useState<File | null>(null);
  const [parsedFileData, setParsedFileData] =
    useState<parsedFileDataType | null>(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(false);
  const [nameForScrapper, setNameForScrapper] = useState<string>("");
  const { data: userData } = useUser();
  const isAdmin = (userData.role === 1|| userData.role===3);
  const { data, error, mutate, isLoading } = useProducts();
  const [suggestedCerts, setSuggestedCerts] = useState([]);
  const [requiredCerts, setRequiredCerts] = useState<any>([]);

  const getSuggestedCertificates = async (componentNames: string[]) => {
    return setSuggestedCerts(
      await materialAPI.getSuggestedCertificates(componentNames)
    );
  };

  const getRequiredCertificates = async () => {
    return setRequiredCerts(await materialAPI.getRequiredCertificates());
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'short', day: 'numeric', year: 'numeric'
  };
  function getCurrentDateTime() {
    const now = new Date();
    
    // Options for the time part
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour12: true, hour: '2-digit', minute: '2-digit'
    };
    const timePart = now.toLocaleTimeString('en-US', timeOptions);
  
    // Options for the date part
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short', day: 'numeric', year: 'numeric'
    };
    const datePart = now.toLocaleDateString('en-US', dateOptions);
  
    // Combine date and time parts
    const currentDateTime = `${datePart}, ${timePart}`;
    return currentDateTime;
  }
  useEffect(() => {
    if (!error && data) {
      setProducts(data[0]);
      setFilterProduct({
        ...filterProduct,
        products:data[0]
      })
    }
  }, [data, error]);

  useEffect(() => {
    if (step === 1) {
      setCancelText("Cancel");
    } else if (step === 2) {
      setCancelText("Go back");
      setNextText("Next");
    } else if (step === 3) {
      setNextText("Create product");
    }
  }, [step]);

  const cancelCallback = (closeModal: () => void) => {
    if (step === 1) {
      formik.resetForm();
      clearModalData();
      closeModal();
    } else setStep(step - 1);
  };

  const nextCallback = async (closeModal: () => void) => {
    const validateForm = await formik.validateForm();
    if (!_.isEmpty(validateForm)) return;
    else if (step === 2) {
      setIsNextButtonDisabled(true);
      const parsedData = await parseFile();
      if (parsedData) {
        setParsedFileData({
          ...parsedData,
          materials: parsedData.materials.map((value: any, index: number) => ({
            ...value,
            id: index,
          })),
          filePath: parsedData.filePath,
        });
        const componentNames = parsedData?.materials?.map(
          (material: any) => material.componentName
        );

        if (componentNames) {
          await getSuggestedCertificates(componentNames);
          await getRequiredCertificates();
        }

        setStep(step + 1);
      }
      setIsNextButtonDisabled(false);
    } else if (step === 3) {
      await formik.handleSubmit();
      closeModal();
    } else setStep(step + 1);
  };

  const parseFile = (): any => {
    const form_data = new FormData();
    if (file !== null) {
      form_data.append("file", file);
      form_data.append("productName", formik.values.name);
      return fetcher({
        key: "collectionProductMaterials/parseFile",
        data: form_data,
      });
    } else toast.info("Please upload a file");
  };
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      id: undefined,
      name: "",
      type: [],
      standards: [],
      markets: [],
      description: "",
      developmentStatus: {},
      manufacturer: "",
      customer: `${userData?.firstName} ${userData?.lastName}`,
      critical: true,
    },

    onSubmit: async (values, { setErrors }) => {
      try {
        const resp = await productApi.saveProduct(
          undefined,
          values.name,
          values.type,
          values.standards,
          values.markets,
          values.description,
          values.developmentStatus,
          values.manufacturer,
          values.customer,
          parsedFileData?.filePath,
          { name: file?.name || "" },
          parsedFileData?.materials.map((material: any) => ({
            ...material,
            id: undefined,
          })) || []
        );
        formik.handleReset("");
        //TODO UNCOMMENT START SCRAPING

        // if (resp?.materials?.length) {
        //   await scrappingFetcher(
        //     resp.materials.map((material: any) => ({
        //       materialId: material.id,
        //       search: material.componentName,
        //     }))
        //   );
        // }
        if (resp) {
          toast.success("Product added");
          const productUrl = window.location.origin + "/#/products/" + resp.id;
          // if (userData?.email && !userData.email.includes('@certain.li')) {
          //   console.log('sending mail')
          //   const currentTime = getCurrentDateTime()
          //   console.log(currentTime,'currentTime')
          //   const emailData = {
          //     target: "product",
          //     itemName: values.name,
          //     itemUrl: productUrl,
          //     userEmail: userData?.email,
          //     userFirstName: userData?.firstName,
          //     userLastName: userData?.lastName,
          //     userCompany: userData?.company?.name,
          //     currentTime:currentTime
          //   };
          //   console.log(emailData,'emailData')
          //   productComponentNotification(emailData);
          // }
         
        }

        await mutate();
        clearModalData();
      } catch (e) {
        toast.error(e?.response?.data);
        // @ts-ignore
        setErrors({ error: e?.response?.data });
      }
    },
  });
  const clearModalData = () => {
    setFile(null);
    setParsedFileData(null);
    setNextText("Next");
  };

  const addScrapper = async () => {
    try {
      if (!nameForScrapper) {
        toast.info("Enter a product name");
        return;
      }

      let rows: any = nameForScrapper.split(/\r\n|\r|\n/g);
      rows = rows.map((s: any) => s.trim()).join();

      await fetch(`${scrappingUrl}/search?search=${rows}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      setScrapperModalIsOpen(false);
      toast.success("Success");
      setNameForScrapper("");
    } catch (err) {
      toast.error(_.get(err, "response.data.message", "Error"));
    }
  };
  const filterProductOnChange=(item:string|null)=>{
    if (item) {
      setFilterProduct({...filterProduct,selected:item})
      var filterProds = products
      if (item==='Companies') {
        filterProds = products?.filter((product:ProductType)=> product.user.company
     )
    }else if (item==='Benchmark') {
      filterProds = products?.filter((product:ProductType)=> product.user.company==null
     )
    }else{
      setFilterProduct({...filterProduct,products:products})
    }
    setFilterProduct({...filterProduct,products:filterProds, open:!filterProduct.open})
    }

  }
  return (
    <div className="ProductsContainer">
      <CustomModal
        isShown={modalIsOpen}
        // ariaHideApp={false}
        // shouldCloseOnOverlayClick
        setModalShown={() => setModalIsOpen(false)}
      >
        <div
          style={{
            borderBottomLeftRadius: "2rem",
          }}
        >
          <ModalContentWrapper
            setModalIsOpen={setModalIsOpen}
            title="Add a product"
            cancelText={cancelText}
            cancelCallback={cancelCallback}
            nextText={nextText}
            nextCallback={nextCallback}
            setStep={setStep}
            isNextButtonDisabled={isNextButtonDisabled}
          >
            <AddProduct
              step={step}
              formik={formik}
              file={file}
              setFile={setFile}
              parsedFileData={parsedFileData}
              setParsedFileData={setParsedFileData}
              suggestedCerts={suggestedCerts}
              requiredCerts={requiredCerts}
            />
          </ModalContentWrapper>
        </div>
      </CustomModal>

      <Modal
        isOpen={scrapperModalIsOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setScrapperModalIsOpen(false)}
      >
        <ModalContentWrapper
          setModalIsOpen={setScrapperModalIsOpen}
          title="Scrapper"
          cancelText={"Cancel"}
          cancelCallback={(closeModal) => closeModal()}
          nextText={"Add"}
          nextCallback={addScrapper}
          setStep={setStep}
          isNextButtonDisabled={isNextButtonDisabled}
        >
          <Scrapper
            nameForScrapper={nameForScrapper}
            setNameForScrapper={setNameForScrapper}
          />
        </ModalContentWrapper>
      </Modal>

      <Modal
        isOpen={scrapperListModalIsOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick
        onRequestClose={() => setScrapperListModalIsOpen(false)}
      >
        <ModalContentWrapper
          setModalIsOpen={setScrapperListModalIsOpen}
          title="Scraping list"
          cancelText={"Close"}
          cancelCallback={(closeModal) => closeModal()}
          setStep={setStep}
          isNextButtonDisabled={isNextButtonDisabled}
        >
          <ScrapperList />
        </ModalContentWrapper>
      </Modal>

      <div className="ProductsContainer__header">
        <div className="ProductsContainer__title">Products</div>
        <div className="ProductsContainer__controls">
          <div>
            {isAdmin && (
              <Button
                label="Filter"
                icon={filter}
                className={"relative"}
                color={BUTTON_COLORS.normal}
                onClick={()=>setFilterProduct({...filterProduct,open:!filterProduct.open})}
              />
            )}
           {filterProduct.open && <div className="bg-white rounded-md shadow py-2 px-1 m-1 absolute ">
              <ul className="flex gap-1 flex-col w-32" 
               onClick={(e) => {
                const target = e.target as HTMLElement;
               
                  filterProductOnChange(target.textContent)
                
              }}
              >
                <li className="text-gray-500 hover: cursor-pointer hover:bg-gray-100 hover: rounded ">
                  All
                </li>
                <li className="text-gray-500 hover: cursor-pointer hover:bg-gray-100 hover: rounded">
                  Companies
                </li>
                <li className="text-gray-500 hover: cursor-pointer hover:bg-gray-100 hover: rounded">
                  Benchmark
                </li>
              </ul>
            </div>}
          </div>

          {/* {isAdmin && (
            <Button
              label="Scraping list"
              // icon={refresh}
              color={BUTTON_COLORS.normal}
              onClick={() => setScrapperListModalIsOpen(true)}
            />
          )} */}
          {/* {isAdmin && (
            <Button
              label="Start scraping"
              // icon={refresh}
              color={BUTTON_COLORS.normal}
              onClick={() => setScrapperModalIsOpen(true)}
            />
          )} */}
          <Button
            label="Refresh list"
            icon={refresh}
            color={BUTTON_COLORS.normal}
            onClick={mutate}
          />
          <Button
            label="Add new product"
            icon={plus}
            onClick={() => setModalIsOpen(true)}
          />
        </div>
      </div>

      {!error ? (
        <Products
          products={filterProduct?.products || []}
          loading={isLoading}
          mutate={mutate}
        />
      ) : (
        <div>Error</div>
      )}
    </div>
  );
};

export default ProductsContainer;
